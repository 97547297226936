import React from 'react'
import NavMenu from '../NavMenu'

export default function Scaffold(props: ScaffoldProps) {
  return (
    <div style={{ width: '100vw', height: '100vh', display: 'flex' }}>
      <NavMenu />
      <div style={styles.container as any}>
        {props.children}
      </div>
    </div >
  )
}

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
    flexDirection: "column"
  }
}

interface ScaffoldProps {
  children: React.ReactNode
}