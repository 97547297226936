import { useState } from 'react'
import { Alert, Button, Divider, Input, message } from "antd"
import IUser from "../models/User"
import styled from "styled-components"
import { CheckOutlined } from '@ant-design/icons'
import { sendOtp, verifyOtp } from '../data/EkisClient'

const VerifyUser = (props: VerifyUserProps) => {
    return (
        <div>
            <Row>
                <div>
                    <h4>Phone Number</h4>
                    <div>{props.user.phoneNumber}</div>
                </div>
                <div>
                    <SendOtpButton title='Verify SMS' to={props.user.phoneNumber!} channel='sms' />
                    <br />
                    <SendOtpButton title='Verify WhatsApp' to={props.user.phoneNumber!} channel='whatsapp' />
                    <br />
                    <SendOtpButton title='Verify Voice' to={props.user.phoneNumber!} channel='call' />
                </div>
            </Row>
            <Divider />
            <Row>
                <div>
                    <h4>Email Address</h4>
                    <div>{props.user.email}</div>
                </div>
                <div>
                    <SendOtpButton title='Verify Email' to={props.user.email!} channel='email' />
                </div>
            </Row>

        </div>
    )
}

const SendOtpButton = (props: SendOtpButtonProps) => {
    type Result = 'pending' | 'incorrect' | 'approved'

    const [sending, setSending] = useState(false)
    const [isChecking, setChecking] = useState(false)
    const [isSent, setSent] = useState(false)
    const [otp, setOtp] = useState('')
    const [result, setResult] = useState<Result>('pending')

    const handleSendOtp = async () => {
        setSending(true)
        try {
            await sendOtp(props.to, props.channel)
            setSent(true)
        } catch (e) {
            message.error((e as any).msg)
        }
        setSending(false)
    }

    const handleVerify = async () => {
        if (!otp) {
            message.error('Please enter the OTP')
            return
        }
        setChecking(true)
        try {
            const result = await verifyOtp(props.to, otp)
            if (result.status === 'approved') {
                setResult('approved')
            } else if (!result.valid) {
                setResult('incorrect')
            }
        } catch (e) {
            message.error((e as any).msg)
        }
        setChecking(false)
    }

    if (!isSent) {
        return <Button onClick={handleSendOtp} loading={sending} style={{ width: 200, marginBottom: 6 }}>{props.title}</Button>
    }

    if (result === 'approved') {
        return <Alert message="Verified" type="success" showIcon />
    }

    return (
        <Row>
            <Input
                style={{
                    width: 160,
                    marginRight: 4,
                    borderColor: result === 'incorrect'
                        ? 'red'
                        : undefined
                }}
                placeholder='Enter OTP'
                value={otp}
                onChange={e => {
                    setResult('pending')
                    setOtp(e.target.value)
                }}
            />
            <Button shape="circle" icon={<CheckOutlined />} onClick={handleVerify} loading={isChecking} />
        </Row>
    )
}

interface VerifyUserProps {
    user: IUser
}

interface SendOtpButtonProps {
    to: string,
    channel: string,
    title: string
}

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export default VerifyUser