import { ReloadOutlined } from "@ant-design/icons";
import { Button, Divider, Empty, message, Skeleton } from "antd";
import { CSSProperties, useEffect, useState } from "react";
import { fixWithoutRounding } from "../common/util";
import { getPbtxBalance, getTransferHistory } from "../data/EkisClient";
import ITransaction from "../models/Transaction";
import IUser from "../models/User";
import TransactionItem from "./TransactionItem";

export default function TransactionList(props: ITransactionListProps) {
  const [data, setData] = useState<ITransaction[]>([])
  const [accountInfo, setAccountInfo] = useState<any>()
  const [loading, setLoading] = useState(false)

  async function fetchData() {
    setLoading(true)
    Promise.all([
      getTransferHistory(props.user.accountId!).then(data => setData(data)),
      getPbtxBalance(props.user.accountId!).then(info => setAccountInfo(info))
    ])
      .catch(e => {
        console.error(e)
        setLoading(false)
        message.error(e.msg || e.message || 'Unable to get data')
      })
      .then(() => setLoading(false))
  }

  useEffect(() => {
    fetchData()
  }, [])

  const renderContent = () => {
    if (loading) {
      return (
        <>
          <Skeleton active />
          <Skeleton active />
        </>
      )
    }
    return data.length === 0
      ? <Empty />
      : data.map(item => <div key={item.txhash} >
        <TransactionItem tx={item} />
        <Divider />
      </div>)

  }

  const balance = loading
    ? '--'
    : (accountInfo
      ? fixWithoutRounding(accountInfo.amount, 2)
      : '--')

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div>
          <div style={styles.balance}>
            <span>{balance}</span>
            <span style={styles.currency}> ekis</span>
          </div>
          <div style={styles.caption}>{loading ? '' : accountInfo?.amount}</div>
        </div>
        <Button shape='circle' icon={<ReloadOutlined />} onClick={fetchData} />
      </div>
      {renderContent()}
    </div>
  )
}

interface ITransactionListProps {
  user: IUser
}

const styles: { [key: string]: CSSProperties } = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
    fontSize: 16
  },
  currency: {
    fontWeight: 'bold',
    color: 'var(--primary-color)'
  },
  balance: {
    fontWeight: 'bold',
    color: 'var(--secondary-color)'
  },
  caption: {
    fontSize: 11,
    color: 'grey'
  }
}