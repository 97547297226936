import { AuditOutlined, DollarCircleOutlined, EditOutlined, KeyOutlined, NotificationOutlined, QrcodeOutlined, TableOutlined, UnorderedListOutlined } from '@ant-design/icons'
import { Button, Divider, Drawer, Dropdown, Menu, Radio, Table, message } from 'antd'
import _ from 'lodash'
import { DateTime } from 'luxon'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import Scaffold from '../components/Scaffold'
import StatsRow from '../components/StatsRow'
import Titlebar from '../components/Titlebar'
import { EkisEnv, getEkisEnv } from '../config'
import { getUsers } from '../data/EkisClient'
import metamapIconImage from '../images/metamap.png'
import IStat from '../models/IStat'
import { VerificationName } from '../models/IVerification'
import IUser, { AccountType, UserRole, UserStatus } from '../models/User'
import UserFilters from '../models/UserFilters'
import { showSendNotificationDialog } from '../notification/SendNotificationScreen'
import { showTopupDialog } from '../topup/Topup'
import TransactionList from '../transactions/TransactionList'
import { showClabeAccounts } from './ClabeAccountList'
import { showErpForm } from './ErpForm'
import { showBlockchainData } from './PublicKeyDialog'
import { showUserFormDialog } from './UserForm'
import UserItem from './UserItem'
import { showUserQr } from './UserQr'
import { showUserTxExportDialog } from './UserTxExport'
import { showUserAuditList } from './user-audit/UserAuditList'

const UsersScreen = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState<IUser[]>([])
  const [filters, setFilters] = useState<UserFilters>({
    role: undefined,
    status: UserStatus.ACTIVE
  })
  // eslint-disable-next-line
  const [accountType, setAccountType] = useState<AccountType>()
  const [refreshing, setRefreshing] = useState(false)
  const [showTxUser, setShowTxUser] = useState<IUser>()

  const data = useMemo(() => {
    const isFilterPresent = Object.values(filters).find(x => x !== undefined)
    if (!isFilterPresent) return users

    return users.filter((u: IUser) => {
      return _.keys(filters).every(filter => {
        const val = (filters as any)[filter]
        if (filter === 'status') {
          switch (val) {
            case 'all':
              return true
            case UserStatus.DISABLED:
              return u.status === UserStatus.DISABLED
            case UserStatus.INCOMPLETE:
              return (u.status === UserStatus.INCOMPLETE && !u.accountId)
            case UserStatus.ACTIVE:
              return !!u.accountId && u.status !== UserStatus.DISABLED
          }
        }
        return val === undefined || (u as any)[filter] === val
      })
    })
  }, [users, filters])

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '100px',
      render: (id: string, user: IUser) => {
        return <Link
          to={`/users/${user.id}`}
          style={{ fontSize: 11 }}
        >
          {id}
        </Link>
      },
    },
    {
      title: 'AccountID',
      dataIndex: 'accountId',
      width: '100px',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '300px',
      render: (name: string, user: IUser) => <UserItem image={user.image} name={name} />,
      sorter: (a: IUser, b: IUser) => a.name.localeCompare(b.name),
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      width: '100px',
    },
    {
      title: 'Type',
      width: '100px',
      dataIndex: 'accountType',
      sorter: (a: IUser, b: IUser) => a.accountType.localeCompare(b.accountType),
    },
    {
      title: 'Registerd On',
      width: '150px',
      dataIndex: 'createdOn',
      render: (date: string) => {
        return <div>{DateTime.fromISO(date).toFormat('MMMM dd, yyyy hh:mm')}</div>
      },
      sorter: (a: IUser, b: IUser) => DateTime.fromISO(a.createdOn).valueOf() - DateTime.fromISO(b.createdOn).valueOf(),
      defaultSortOrder: 'descend' as any,
    },
    {
      title: 'DOB',
      dataIndex: 'dob',
      width: '150px',
      render: (dob: string, user: IUser) => {
        return (user.role === UserRole.USER && user.dob)
          ? moment(dob).format('YYYY MMM DD')
          : ''
      },
      sorter: (a: IUser, b: IUser) => {
        if (!a.dob) return -1
        if (!b.dob) return 1
        return moment(a.dob).valueOf() - moment(b.dob).valueOf()
      },
    },
    {
      title: 'Device Name',
      dataIndex: 'deviceId',
      sorter: (a: IUser, b: IUser) => (a.deviceId || '').localeCompare(b.deviceId || ''),
    },
    // {
    //   title: 'Verification',
    //   dataIndex: 'status',
    //   sorter: (a: IUser, b: IUser) => {
    //     const aStatus = a.status || VerificationStatus.UNINITIALIZED
    //     const bStatus = b.status || VerificationStatus.UNINITIALIZED
    //     return aStatus.localeCompare(bStatus)
    //   }
    // },
    {
      title: 'Actions',
      render: renderActions
    }
  ];

  const stats: IStat[] = useMemo(() => {
    const userStat: IStat = {
      name: 'Usuarios totales',
      value: users.length,
      icon: 'user',
    }
    const alliedCompaniesStat: IStat = {
      name: 'Empresas Aliadas',
      value: 0,
      icon: 'company',
    }
    const personalStat: IStat = {
      name: 'Wallets Personales',
      value: 0,
      icon: 'user_phone',
    }
    const companyStat: IStat = {
      name: 'Wallets Empresariales',
      value: 0,
      icon: 'company_phone',
    }
    const ownerStat: IStat = {
      name: 'Wallets Negocios',
      value: 0,
      icon: 'shop_phone',
    }
    for (const user of users) {
      switch (user.accountType) {
        case AccountType.PERSONAL:
          personalStat.value += 1
          break;
        case AccountType.CORPORATE:
          companyStat.value += 1
          break;
        case AccountType.OWNER:
          ownerStat.value += 1
          break;
      }
    }
    return [userStat, alliedCompaniesStat, personalStat, companyStat, ownerStat]
  }, [users])

  useEffect(() => {
    setLoading(true)
    getUsers({ includeDisabled: true })
      .then(users => {
        setUsers(users)
        setLoading(false);
        (window as any).users = users
      })
      .catch(error => {
        message.error('Unable to load users')
        setLoading(false)
      })
    // filtersRef.current = filters
  }, [])

  useEffect(() => {
    const accountType = searchParams.get('accountType')
    if (accountType) {
      setAccountType(accountType as any)
    } else {
      setAccountType(undefined)
    }
  }, [searchParams])

  function renderActions(user: IUser) {
    const personalId = _.find(user.verifications, ['name', VerificationName.PERSONAL_ID])
    const proofOfResidency = _.find(user.verifications, ['name', VerificationName.PROOF_OF_RESIDENCY])

    const menu = (
      <Menu>
        {user.accountId ? <>
          <Menu.Item key="qr" icon={<QrcodeOutlined />} onClick={() => showUserQr({ user })}>
            Generate QR
          </Menu.Item>
          <Menu.Item key="view-tx" icon={<UnorderedListOutlined />} onClick={() => setShowTxUser(user)}>
            View Transactions
          </Menu.Item>
          <Menu.Item key="publicKey" icon={<KeyOutlined />} onClick={() => showBlockchainData(user)}>
            Get Blockchain Data
          </Menu.Item>
          <Menu.Item key="export-tx" icon={<TableOutlined />} onClick={() => showUserTxExportDialog({ user })}>
            Export transactions
          </Menu.Item>
        </> : null}
        {getEkisEnv() === EkisEnv.PILOT ? null : (
          <Menu.Item key="topup" icon={<DollarCircleOutlined />} onClick={() => showTopupDialog({ user })}>
            Topup account
          </Menu.Item>
        )}
        <Menu.Item key="edit" icon={<EditOutlined />} onClick={() => showUserFormDialog({ user })}>
          Edit
        </Menu.Item>
        <Menu.Item key="send-not" icon={<NotificationOutlined />} onClick={() => showSendNotificationDialog({ users, initialUsers: [user] })}>
          Send notification
        </Menu.Item>
        <Menu.Item key="audits" icon={<AuditOutlined />} onClick={() => showUserAuditList({ userId: user.id })}>
          Show History
        </Menu.Item>
        <Menu.Item key="clabe-accounts" icon={<AuditOutlined />} onClick={() => showClabeAccounts({ userId: user.id })}>
          Show Clabe Accounts
        </Menu.Item>
        {personalId?.verificationId ? (
          <Menu.Item key="metamap" icon={
            <img alt='' src={metamapIconImage} style={{ width: 18, height: 18 }} />
          }>
            <a href={`https://dashboard.getmati.com/identity/624d7f4d6cc7f4001cb5d2bb/verification/${personalId.verificationId}`}>
              Personal ID
            </a>
          </Menu.Item>
        ) : null}
        {proofOfResidency?.verificationId ? (
          <Menu.Item key="7" icon={
            <img alt='' src={metamapIconImage} style={{ width: 18, height: 18 }} />
          }>
            <a href={`https://dashboard.getmati.com/identity/624d7f4d6cc7f4001cb5d2bb/verification/${proofOfResidency.verificationId}`}>
              Proof of Residency
            </a>
          </Menu.Item>
        ) : null}
      </Menu>
    );
    return (
      <Dropdown overlay={menu}>
        <Button>Actions</Button>
      </Dropdown>
    )
  }

  const setFilter = (filterName: string) => (e: any) => {
    const value = e.target.value
    const filterValue = value === 'all' ? undefined : value
    setFilters({ ...filters, [filterName]: filterValue })
  }

  const refreshData = async () => {
    setRefreshing(true)
    try {
      const data = await getUsers({ includeDisabled: true })
      setUsers(data)
      message.success('Data refreshed')
    } catch (e) {
      message.error((e as any).message)
      console.error(e)
    }
    setRefreshing(false)
  }

  return (
    <Scaffold>
      <Titlebar title='Usuarios' />
      <StatsRow stats={stats} />
      <div style={styles.row}>
        <Button
          key='refresh'
          style={{ marginRight: 10 }}
          onClick={refreshData}
          loading={refreshing}
        >
          Refresh
        </Button>
        <Radio.Group
          key='status'
          buttonStyle="solid"
          value={filters.status || "all"}
          onChange={setFilter('status')}
        >
          <Radio.Button value="all">All</Radio.Button>
          <Radio.Button value={UserStatus.ACTIVE}>Active</Radio.Button>
          <Radio.Button value={UserStatus.INCOMPLETE}>Incomplete</Radio.Button>
          <Radio.Button value={UserStatus.DISABLED}>Disabled</Radio.Button>
        </Radio.Group>
        <Radio.Group
          key='role'
          buttonStyle="solid"
          value={filters.role || "all"}
          onChange={setFilter('role')}
          style={{ margin: '0 10px' }}
        >
          <Radio.Button value="all">All</Radio.Button>
          <Radio.Button value={UserRole.USER}>Users</Radio.Button>
          <Radio.Button value={UserRole.MERCHANT}>Merchants</Radio.Button>
        </Radio.Group>
        <Button
          key='notification'
          onClick={() => showSendNotificationDialog({ users, targetType: 'group' })}
        >
          Send notification
        </Button>
        <Button
          key='set-erp'
          onClick={() => showErpForm({})}
          style={{ margin: '0 10px' }}
        >
          ERP
        </Button>
      </div>
      <Divider />
      <Table
        loading={loading}
        columns={columns}
        dataSource={data}
        rowKey='id'
        pagination={false}
      />
      <Drawer
        title={`${showTxUser?.name}'s transactions`}
        placement='right'
        closable={false}
        onClose={() => setShowTxUser(undefined)}
        visible={!!showTxUser}
      >
        {showTxUser ? <TransactionList user={showTxUser!!} /> : null}
      </Drawer>
    </Scaffold >
  )
}

const styles = {
  row: {
    display: 'flex',
    padding: 10,
    justifyContent: 'flex-end'
  }
}

export default UsersScreen
