export interface IVerification {
  name: VerificationName
  status: VerificationStatus
  source?: VerificationSource
  verificationId?: string // if verified by metamap
}

export enum VerificationName {
  PERSONAL_ID = 'personalIdentification',
  PROOF_OF_RESIDENCY = 'proofOfResidency'
}

export enum VerificationStatus {
  UNINITIALIZED = 'UNINITIALIZED',
  PROCESSING = 'PROCESSING',
  VERIFIED = 'VERIFIED',
  REJECTED = 'REJECTED',
  REVIEW_NEEDED = 'REVIEW_NEEDED'
}

export enum VerificationSource {
  METAMAP = 'METAMAP',
  MANUAL = 'MANUAL'
}
