import { Button, Form, Input, message, Modal, Radio, Row, Select } from 'antd'
import { useState } from 'react'
import ReactDOM from 'react-dom'
import { sendNotificatoin } from '../data/EkisClient'
import ISendNotificationInput from '../models/ISendNotificationInput'
import IUser from '../models/User'

const topics = [
  { label: 'All', value: 'fixpayments_topic' },
  { label: 'Users', value: 'fixpayments_topic_user' },
  { label: 'Merchants', value: 'fixpayments_topic_merchant' }
]

export default function SendNotificationScreen(props: ISendNotificationScreenProps) {
  const [loading, setLoading] = useState(false)
  const [users] = useState<IUser[]>(props.users)
  const [targetType, setTargetType] = useState<TargetType>(props.targetType || 'user')

  async function handleSubmit(values: any) {
    console.log(values)

    try {
      setLoading(true)
      
      const params: ISendNotificationInput = {
        notification: {
          title: values.title,
          content: values.content
        }
      }
      if (values.topics) {
        params.topics = [values.topics]
      } else {
        params.userIds = values.userIds
      }

      console.log(values, params)
      await sendNotificatoin(params)
      message.success('Notification sent')
    } catch (e) {
      message.error('Notification failed')
      console.error(e);
    }
    setLoading(false)
  }

  return (
    <div>
      <Form
        layout='vertical'
        onFinish={handleSubmit}
      >
        <Form.Item
          name='targetType'
          label='Target type'
          required
          initialValue={targetType}
        >
          <Radio.Group
            buttonStyle="solid"
            style={{ marginLeft: 10 }}
            onChange={e => setTargetType(e.target.value)}
          >
            <Radio.Button value="user">User</Radio.Button>
            <Radio.Button value="group">Group</Radio.Button>
          </Radio.Group>
        </Form.Item>
        {targetType === 'user' ? (
          <Form.Item
            name='userIds'
            label='To'
            required
            initialValue={props.initialUsers?.map(x => x.id)}
          >
            <Select
              mode="multiple"
              showArrow
            >
              {users.map(x => <Select.Option key={x.id} value={x.id}>{x.name}</Select.Option>)}
            </Select>
          </Form.Item>
        ) : (
          <Form.Item
            name='topics'
            label='To'
            required
            initialValue={topics[0].value}
          >
            <Select>
              {topics.map(x => <Select.Option key={x.value} value={x.value}>{x.label}</Select.Option>)}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          name='title'
          label='Title'
          required
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='content'
          label='Content'
        >
          <Input.TextArea />
        </Form.Item>
        <Row align='bottom' justify='end'>
          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit">
              Send
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div >
  )
}

export function showSendNotificationDialog(props: ISendNotificationScreenProps) {
  const mountTarget = document.createElement('div')
  document.body.appendChild(mountTarget)

  function callback(params: any) {
    ReactDOM.unmountComponentAtNode(mountTarget)
    mountTarget.parentElement?.removeChild(mountTarget)
  }

  ReactDOM.render(
    <Modal
      title='Send notification'
      visible={true}
      footer={null}
      onCancel={() => callback(undefined)}>
      <SendNotificationScreen {...props} />
    </Modal>,
    mountTarget
  )
}

type TargetType = 'user' | 'group'
export interface ISendNotificationScreenProps {
  users: IUser[]
  initialUsers?: IUser[]
  targetType?: TargetType
}