import { CheckCircleOutlined, CheckOutlined, DeleteOutlined, ExclamationCircleOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Descriptions, Divider, Dropdown, Menu, Modal, PageHeader, Table, message } from 'antd';
import history from "history/browser";
import _ from "lodash";
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Scaffold from '../components/Scaffold';
import { approveAdmin, deleteAdmin, getAdmins, sendAdminInvitation } from '../data/EkisClient';
import IAdmin from '../models/IAdmin';
import { showAdminForm } from './AdminForm';

export default function AdminsScreen() {
  const [loading, setLoading] = useState(false)
  const [admins, setAdmins] = useState<IAdmin[]>([])
  const [approveId, setApproveId] = useState<string>()

  const location = useLocation()

  const counts = useMemo(() => {
    const data = {
      registered: 0,
      unregistered: 0
    }
    if (!admins) {
      return data
    }
    admins.forEach(x => {
      if (x.registered) {
        data.registered += 1
      } else {
        data.unregistered += 1
      }
    })
    return data
  }, [admins])

  const columns: any[] = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'UID',
      dataIndex: 'uid',
    },
    {
      title: 'Email',
      dataIndex: 'email'
    },
    {
      title: 'Registerd?',
      dataIndex: 'registered',
      render: (flag: boolean) => flag ? 'Yes' : 'No',
      sorter: (a: any, b: any) => a.registered - b.registered,
    },
    {
      title: 'Approved?',
      dataIndex: 'approved',
      render: (flag: boolean) => flag ? 'Yes' : 'No',
      sorter: (a: any, b: any) => a.approved - b.approved,
    },
    {
      title: 'Actions',
      render: renderActions
    }
  ]

  function renderActions(admin: IAdmin) {
    const menu = (
      <Menu>
        {(admin.uid && !admin.approved) ? (
          <Menu.Item key="0" icon={<CheckOutlined />} onClick={() => showApproveConfirm(admin)}>
            Approve
          </Menu.Item>
        ) : null}
        <Menu.Item key="1" icon={<DeleteOutlined />} onClick={() => showDeleteConfirm(admin)}>
          Delete
        </Menu.Item>
        <Menu.Item key="2" icon={<MailOutlined />} onClick={() => {
          sendAdminInvitation(admin.id)
            .then(() => message.success('Invitation sent'))
            .catch(e => message.error(`Unable to send invitation: ${(e as any).message}`))
        }}>
          Resend Invitation
        </Menu.Item>
      </Menu>
    );
    return (
      <Dropdown overlay={menu}>
        <Button>Actions</Button>
      </Dropdown>
    )
  }

  const showApproveConfirm = (admin: IAdmin) => {
    Modal.confirm({
      title: `Approve user ${admin.email}?`,
      icon: <CheckCircleOutlined />,
      content: 'This will allow the user to acccess ekis UMS',
      okText: 'Yes',
      cancelText: 'No',
      async onOk() {
        try {
          await approveAdmin(admin.id)
          message.success('Admin approved')
        } catch (e) {
          message.error(`Unable to approve admin: ${(e as any).message}`)
        }
        getAdmins().then(admins => setAdmins(admins))
      },
      onCancel() { },
    })
  }

  const showDeleteConfirm = (admin: IAdmin) => {
    Modal.confirm({
      title: `Are you sure you want to delete?`,
      icon: <ExclamationCircleOutlined />,
      content: 'If the user has already registerd their account will be deleted!',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        try {
          await deleteAdmin(admin.id)
          message.success('Admin deleted')
        } catch (e) {
          message.error(`Unable to delete admin: ${(e as any).message}`)
        }
        getAdmins().then(admins => setAdmins(admins))
      },
      onCancel() { },
    })
  }

  function fetchData() {
    setLoading(true)
    getAdmins()
      .then(admins => {
        setAdmins(admins)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        message.error('Unable to load admins')
      })
  }

  async function handleInviteAdmin() {
    await showAdminForm()
    getAdmins()
      .then(admins => setAdmins(admins))
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const id = queryParams.get('approve')
    if (id) {
      setApproveId(id)
    }
    history.replace({ search: '' })
  }, [])

  useEffect(() => {
    (async () => {
      if (approveId && !_.isEmpty(admins)) {
        const admin = admins.find(x => x.id === approveId)
        if (admin) {
          await showApproveConfirm(admin)
          setApproveId(undefined)
        }
      }
    })()
  }, [approveId, admins])

  return (
    <Scaffold>
      <PageHeader
        ghost={false}
        title="Admins"
        subTitle={`Total admins: ${admins.length}`}
      >
        <Descriptions size="small" column={3}>
          <Descriptions.Item label="Registered">{counts.registered}</Descriptions.Item>
          <Descriptions.Item label="Unregistered">{counts.unregistered}</Descriptions.Item>
          <Descriptions.Item contentStyle={{ justifyContent: 'end' }}>
            <Button onClick={handleInviteAdmin}>Invite admin</Button>
          </Descriptions.Item>
        </Descriptions>
      </PageHeader>
      <Divider />
      <Table
        loading={loading}
        rowSelection={{ type: 'checkbox' }}
        columns={columns}
        dataSource={admins}
        rowKey='id'
        pagination={false}
      />
    </Scaffold >
  )
}
