import { SearchOutlined } from '@ant-design/icons';
import { Alert, Button, Divider, Input, message, Modal, PageHeader, Select, Table, Tooltip } from 'antd';
import { DateTime } from 'luxon';
import moment from 'moment';
import { useState } from 'react';
import styled from 'styled-components';
import Scaffold from '../components/Scaffold';
import { getUsers } from '../data/EkisClient';
import IUser, { UserRole } from '../models/User';
import UserItem from '../users/UserItem';
import VerifyUser from './VerifyUser';

export default function SupportScreen() {
  const [filterBy, setFilterBy] = useState('accountId')
  const [filterValue, setFilterValue] = useState('')
  const [isFinding, setFinding] = useState(false)
  const [showCloseConfirm, setShowCloseConfirm] = useState(false)
  const [data, setData] = useState<IUser[]>([])
  const [selected, setSelected] = useState<IUser | undefined>(undefined)

  function findUsers() {
    const query = { includeDisabled: true, [filterBy]: filterValue }
    setFinding(true)
    getUsers(query)
      .then(data => {
        setData(data)
        setFinding(false)
        console.log(data)
      })
      .catch(error => {
        setFinding(false)
        message.error('Unable to load data')
      })
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '100px',
    },
    {
      title: 'AccountID',
      dataIndex: 'accountId',
      width: '100px',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '200px',
      render: (name: string, user: IUser) => <UserItem image={user.image} name={name} />,
      sorter: (a: IUser, b: IUser) => a.name.localeCompare(b.name),
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      width: '120px',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: '200px',
    },
    {
      title: 'Type',
      width: '100px',
      dataIndex: 'accountType',
      sorter: (a: IUser, b: IUser) => a.accountType.localeCompare(b.accountType),
    },
    {
      title: 'Registerd On',
      width: '150px',
      dataIndex: 'createdOn',
      render: (date: string) => {
        return <div>{DateTime.fromISO(date).toFormat('MMMM dd, yyyy hh:mm')}</div>
      },
      sorter: (a: IUser, b: IUser) => DateTime.fromISO(a.createdOn).valueOf() - DateTime.fromISO(b.createdOn).valueOf(),
      defaultSortOrder: 'descend' as any,
    },
    {
      title: 'DOB',
      dataIndex: 'dob',
      width: '130px',
      render: (dob: string, user: IUser) => {
        return (user.role === UserRole.USER && user.dob)
          ? moment(dob).format('YYYY MMM DD')
          : ''
      },
      sorter: (a: IUser, b: IUser) => {
        if (!a.dob) return -1
        if (!b.dob) return 1
        return moment(a.dob).valueOf() - moment(b.dob).valueOf()
      },
    },
    {
      title: 'Device Name',
      dataIndex: 'deviceId',
      width: '150px',
      sorter: (a: IUser, b: IUser) => (a.deviceId || '').localeCompare(b.deviceId || ''),
    },
    {
      title: 'Type',
      width: '100px',
      dataIndex: 'accountType',
      sorter: (a: IUser, b: IUser) => a.accountType.localeCompare(b.accountType),
    },
    {
      title: 'Actions',
      render: renderActions
    }
  ];


  function renderActions(user: IUser) {
    return (
      <Button onClick={() => setSelected(user)}>Verify</Button>
    )
  }

  return (
    <Scaffold>
      <PageHeader
        title="Customer Support"
      >
      </PageHeader>
      <Divider />
      <main style={{ margin: '0 20px' }}>
        <div style={{ marginBottom: 50 }}>
          <h3>Find User</h3>
          <SpaceBetween>
            <Row>
              <Select
                value={filterBy}
                style={{ width: 120 }}
                onChange={setFilterBy}
                options={[
                  { value: 'phoneNumber', label: 'Phone Number' },
                  { value: 'email', label: 'Email' },
                  { value: 'accountId', label: 'Account ID' }
                ]}
              />
              <Input placeholder="Enter value" value={filterValue} onChange={e => setFilterValue(e.target.value)} />
              <Tooltip title="Find">
                <Button type="primary" shape="circle" icon={<SearchOutlined />} onClick={findUsers} loading={isFinding} />
              </Tooltip>
            </Row>
            <Button onClick={() => {
              setFilterValue('')
              setData([])
            }}>Clear All</Button>
          </SpaceBetween>
        </div>
        <div style={{ marginBottom: 50 }}>
          <h3>Search Result</h3>
          <Table
            columns={columns}
            dataSource={data}
            rowKey='id'
            pagination={false}
          />
        </div>
      </main>
      <Modal
        title={`Verify ${selected?.name}`}
        open={!!selected}
        onCancel={() => setShowCloseConfirm(true)}
        cancelText="Close"
        okButtonProps={{ style: { display: "none" } }}
        width={800}
      >
        {showCloseConfirm ? (
          <Alert
            message="Close Verification?"
            type="warning"
            showIcon
            closable
            onClose={() => setShowCloseConfirm(false)}
            style={{ marginBottom: 20 }}
            action={
              <Button size="small" type="text" onClick={() => {
                setSelected(undefined)
                setShowCloseConfirm(false)
              }}>
                Yes
              </Button>
            }
          />
        ) : null}
        <VerifyUser key={selected?.id!} user={selected!} />
      </Modal>
    </Scaffold >
  )
}

const Row = styled.div`
  display: flex;

  .ant-select {
    width: 180px !important;
  }

  > * {
    margin-right: 8px;
  }

  input {
    width: 400px;
  }
`

const SpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`