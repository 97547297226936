import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Input, message, Modal, Row } from 'antd'
import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { getErpMap, updateErpMap } from '../data/EkisClient'
import IErpMap from '../models/IErpMap'


export default function ErpForm(props: IErpFormProps) {
  // const [erpMap, setErpMap] = useState<IErpMap>({})
  const [erpList, setErpList] = useState<[string, string][]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    try {
      setLoading(true)
      getErpMap().then(data => {
        setErpList(Object.entries(data))
      })
    } catch (e) {
      message.error('Unable to fetch ERP mapping')
      console.error(e);
    }
    setLoading(false)
  }, [])

  async function handleSubmit() {
    try {
      setLoading(true)
      const erpMap: IErpMap = {}
      for (const [accountId, erp] of erpList) {
        if (accountId) {
          erpMap[accountId] = erp
        }
      }
      console.log(erpMap)
      await updateErpMap(erpMap)
      message.success('Saved')
    } catch (e) {
      message.error('Unable to save')
      console.error(e);
    }
    setLoading(false)
  }

  function changeValue(index: number, accountId: string, erp: string) {
    const data = [...erpList]
    data[index] = [accountId, erp]
    setErpList(data)
  }

  function addNewEntry() {
    setErpList([['', ''], ...erpList])
  }

  function removeItem(index: number) {
    const data = [...erpList]
    data.splice(index, 1);
    setErpList(data)
  }

  return (
    <Container>
      <Button type="link" icon={<PlusOutlined />} onClick={addNewEntry} style={{ alignSelf: 'flex-end' }}>Add entry</Button>
      <ErpItem>
        <b style={{ marginLeft: 10 }}>AccountID</b>
        <b>ERP</b>
      </ErpItem>
      {erpList.map(([accountId, erp], index) => {
        return <ErpItem key={index}>
          <Input bordered={false} value={accountId} onChange={e => changeValue(index, e.target.value, erp)} />
          <Input bordered={false} value={erp} onChange={e => changeValue(index, accountId, e.target.value)} />
          <Button onClick={() => removeItem(index)} type="link" icon={<DeleteOutlined />} />
        </ErpItem>
      })}
      <Row align='bottom' justify='end'>
        <Button loading={loading} type="primary" onClick={handleSubmit} style={{ margin: 10 }}>
          Save and Update
        </Button>
      </Row>
    </Container >
  )
}

export function showErpForm(props: IErpFormProps) {
  const mountTarget = document.createElement('div')
  document.body.appendChild(mountTarget)

  function callback(params: any) {
    ReactDOM.unmountComponentAtNode(mountTarget)
    mountTarget.parentElement?.removeChild(mountTarget)
  }

  ReactDOM.render(
    <Modal
      title='Edit ERP mapping'
      visible={true}
      footer={null}
      onCancel={() => callback(undefined)}>
      <ErpForm {...props} />
    </Modal>,
    mountTarget
  )
}

interface IErpFormProps {

}

const ErpItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px;

  > *:nth-child(1) {
    width: 48%;
  }

  > *:nth-child(2) {
    width: 48%;
  }

  > *:nth-child(3) {
    width: 4%;
  }

  &:nth-child(odd) {
    background-color: #f9f9f9;
  }
  

`
const Container = styled.div`
  display: flex;
  flex-direction: column;
`