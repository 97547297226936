import { CSSProperties } from "react"
import IStat from "../models/IStat"
import StatCard from "./StatCard"

export default function StatsRow(props: StatsRowProps) {
  return (
    <div style={styles.container}>
      {props.stats.map((stat, index) => <StatCard key={index} stat={stat} />)}
    </div>
  )
}

interface StatsRowProps {
  stats: IStat[]
}

const styles: { [key: string]: CSSProperties } = {
  container: {
    width: '100%',
    padding: 12,
    background: 'var(--primary-color)',
    display: 'flex',
    flexWrap: 'wrap'
  }
}