import { Button, Form, Input, message } from 'antd';
import { useState, useEffect, useRef } from 'react';
import { QRCode } from "react-qrcode-logo";
import styled from "styled-components";
import { createSessionRequest, getSessionRequest } from '../data/EkisClient';
import ISessionRequest from '../models/ISessionRequest';


const SessionDemoQr = () => {
    const [loading, setLoading] = useState(false)
    const [qrData, setQrData] = useState('')
    const [sessionReqId, setSessionReqId] = useState('')
    const [sessionReq, setSessionReq] = useState<ISessionRequest>()
    const timer = useRef<any>()

    const onSubmit = async (values: any) => {
        setLoading(true)
        try {
            const response = await createSessionRequest(values)
            console.log('response', response)
            setQrData(response.qrText)
            setSessionReqId(response.sessionRequestId)
        } catch (e) {
            const error = e as any
            message.error(error.msg || error.message || 'unknown error')
        }
        setLoading(false)
    }

    useEffect(() => {
        if (sessionReq?.sessionToken) {
            clearInterval(timer.current)
        } else if (sessionReqId && !sessionReq) {
            timer.current = setInterval(() => {
                getSessionRequest(sessionReqId)
                    .then(res => setSessionReq(res))
                    .catch(console.error)
            }, 5000)
        }
    }, [sessionReqId, sessionReq])

    const renderContent = () => {
        if (sessionReq?.sessionToken) {
            return (
                <Container>
                    <h3>Session Token</h3>
                    <Code>{sessionReq.sessionToken}</Code>
                </Container>
            )
        }
        if (sessionReqId) {
            return (
                <Container>
                    <QRCode
                        value={qrData}
                        size={250}
                        logoHeight={70}
                        logoWidth={70}
                    />
                    <div style={{ textAlign: 'center' }}>Scan with ekis mobile app and see reslt here</div>
                </Container>
            )
        }
        return (
            <Form
                layout='vertical'
                onFinish={onSubmit}
            >
                <Form.Item
                    label='User Email'
                    name='email'
                    required
                >
                    <Input placeholder="user@example.com" />
                </Form.Item>
                <Form.Item
                    label='User Phonenumber'
                    name='phoneNumber'
                    required
                >
                    <Input placeholder="+1234567890" />
                </Form.Item>
                <Form.Item
                    required
                    name='client'
                    label='Client Name'
                >
                    <Input placeholder="Firefox on Windows" />
                </Form.Item>
                <Form.Item
                    hidden
                    name='isQrMode'
                    initialValue={true}
                >
                    {/* <Input value={true} /> */}
                </Form.Item>

                <Button
                    htmlType="submit"
                    loading={loading}
                    type="primary"
                    style={{ marginLeft: 'auto' }}
                >
                    Generate QR
                </Button>
            </Form >
        )
    }

    return (
        <Container>
            {renderContent()}
        </Container >
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
const Code = styled.code`
    padding: 4px 8px;
    background: #efefef;
    border-radius: 5px;
`

export default SessionDemoQr