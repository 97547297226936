import { Divider, InputNumber, Modal, Typography } from 'antd'
import { useMemo, useState } from 'react'
import ReactDOM from 'react-dom'
import { QRCode } from 'react-qrcode-logo'
import { getGeneralEnv } from '../config'
import EkisLogo from '../images/ekis_logo.png'
import IUser from '../models/User'
import IUserQrData from '../models/UserQrData'

export default function UserQr(props: UserQrProps) {
  const [qrData, setQrData] = useState<IUserQrData>({ accountId: props.user.accountId!!, amount: props.amount, env: getGeneralEnv() })

  const qrContent = useMemo(() => {
    const data = { ...qrData }
    if (!data.amount) {
      delete data.amount
    }
    return JSON.stringify(data)
  }, [qrData])

  return (
    <div className='column'>
      <div className='row v-center'>
        <Typography.Text style={{ marginRight: 10 }}>
          Amount (optional)
        </Typography.Text>
        <InputNumber onChange={amount => amount && setQrData({ ...qrData, amount: parseFloat(amount.toString()) })} />
      </div>
      <Divider />
      <div style={{ alignSelf: 'center' }}>
        <QRCode
          logoImage={EkisLogo}
          value={qrContent}
          size={250}
          logoHeight={70}
          logoWidth={70}
        />
      </div>
    </div>
  )
}

export function showUserQr(props: UserQrProps) {
  const mountTarget = document.createElement('div')
  document.body.appendChild(mountTarget)

  const callback = () => {
    ReactDOM.unmountComponentAtNode(mountTarget)
    mountTarget.parentElement?.removeChild(mountTarget)
  }

  ReactDOM.render(
    <Modal title={`QR to pay ${props.user.name}`} visible={true} onOk={callback} onCancel={callback}>
      <UserQr {...props} />
    </Modal>,
    mountTarget
  )
}

interface UserQrProps {
  user: IUser
  amount?: number
}