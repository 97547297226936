import React, { CSSProperties } from 'react'
import AntdIcon from '@ant-design/icons'
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

export default function Icon(
  props: Partial<CustomIconComponentProps> & IconProps
) {
  const style: CSSProperties = {
    ...props.style,
    width: props.width || 20,
    height: props.height || 20,
    objectFit: 'contain'
  }
  // if (props.color === 'grey') {
  //   style.filter = 'grayscale(100%)'
  // }
  const icon = `/images/icons/${props.name}.svg`
  return <AntdIcon component={() => <img className={props.className} style={style} src={icon} alt='' />} {...props} />
}

export type IconNames = 'user' | 'company_phone' | 'company' | 'shop_phone' | 'user_phone'
  | 'verifications' | 'logout' | 'verification_success' | 'verification_failed'


interface IconProps {
  name: IconNames
  style?: CSSProperties
}