import { Button, Modal, Table, Tooltip, message } from "antd";
import _ from "lodash";
import { DateTime } from "luxon";
import moment from "moment";
import { useMemo, useState } from "react";
import ReactJson from "react-json-view";
import IMetamapPayload from "../models/IMetamapPayload";
import ReactDOM from "react-dom";
import { simulateMetamapWebhook } from "../data/EkisClient";

const VerificationStepsList = (props: VerificationStepsListProps) => {
  const { data } = props;
  const [selected, setSelected] = useState<IMetamapPayload | undefined>();
  const [isLoading, setLoading] = useState(false)

  const userIdFilters = useMemo(() => {
    const userIds: any = {};
    data.forEach((x) => {
      const userId = _.get(x, "metadata.userId", "");
      userIds[userId] = 1;
    });
    return _.keys(userIds).map((x) => ({
      text: x,
      value: x,
    }));
  }, [data]);

  const processIdFilters = useMemo(() => {
    const ids: any = {};
    data.forEach((x) => {
      const id = _.get(x, "metadata.processId", "");
      ids[id] = 1;
    });
    return _.keys(ids).map((x) => ({
      text: x,
      value: x,
    }));
  }, [data]);

  const columns = [
    {
      title: "Event",
      dataIndex: "eventName",
      render: (eventName: string, item: IMetamapPayload) => item.isManual ? <u>{eventName}</u> : eventName
    },
    {
      title: "Step",
      render: (item: IMetamapPayload) => {
        if (!item.step) return "";
        return (
          <Tooltip title={_.get(item, "step.error.message", "")}>
            <div
              style={{
                color: _.get(item, "step.error", false) ? "red" : undefined,
              }}
            >
              {_.get(item, "step.id", "")}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: "Time",
      dataIndex: "timestamp",
      render: (timestamp: string) =>
        moment(timestamp).format("yyyy MMM DD hh:mm:ss"),
      sorter: (a: IMetamapPayload, b: IMetamapPayload) =>
        DateTime.fromISO(a.timestamp).valueOf() -
        DateTime.fromISO(b.timestamp).valueOf(),
      defaultSortOrder: "descend" as any,
    },
    {
      title: "Status",
      dataIndex: "identityStatus",
    },
    {
      title: "ProcessID",
      render: (item: IMetamapPayload) => _.get(item, "metadata.processId", ""),
      filters: processIdFilters,
      onFilter: (value: string, record: IMetamapPayload) =>
        _.get(record, "metadata.processId", "").includes(value),
    },
    {
      title: "PublicKey",
      render: (item: IMetamapPayload) => (
        <div>{item.metadata?.pubkey || ""}</div>
      ),
    },
    {
      title: "UserID",
      render: (item: IMetamapPayload) => item.metadata?.userId || "",
      filters: userIdFilters,
      onFilter: (value: string, record: IMetamapPayload) =>
        _.get(record, "metadata.userId", "").includes(value),
    },
    {
      title: "Payload",
      render: (item: IMetamapPayload) => {
        return (
          <Button type="link" onClick={() => setSelected(item)}>
            View
          </Button>
        );
      },
    },
  ];

  const resendWebhook = async () => {
    if (!selected) return
    const { flowId, timestamp } = selected
    try {
      setLoading(true)
      await simulateMetamapWebhook(flowId, timestamp)
      message.success('Webhook triggered successfully')
    } catch(e) {
      message.error((e as any).toString())
    }
    setLoading(false)
  }

  return (
    <>
      <Table
        rowSelection={{ type: "checkbox" }}
        columns={columns as any}
        dataSource={data}
        rowKey="id"
        pagination={false}
      />
      <Modal
        title={<div>
          Payload
          <Button
            style={{ marginLeft: 10 }}
            size="small"
            loading={isLoading}
            onClick={resendWebhook}
          >
            Resend Webhook
          </Button>
        </div>}
        visible={!!selected}
        onCancel={() => setSelected(undefined)}
        cancelText="Close"
        okButtonProps={{ style: { display: "none" } }}
        width={800}
      >
        <ReactJson src={selected!} />
      </Modal>
    </>
  );
};

export function showVerificationStepsList(props: VerificationStepsListProps) {
  const mountTarget = document.createElement("div");
  document.body.appendChild(mountTarget);

  function callback() {
    ReactDOM.unmountComponentAtNode(mountTarget);
    mountTarget.parentElement?.removeChild(mountTarget);
  }

  ReactDOM.render(
    <Modal
      title="User Audit"
      visible={true}
      footer={null}
      onCancel={callback}
      width={"fit-content"}
      style={{ maxWidth: "95vw" }}
    >
      <div style={{ overflow: 'auto' }}>
        <VerificationStepsList {...props} />
      </div>
    </Modal>,
    mountTarget
  );
}

interface VerificationStepsListProps {
  data: IMetamapPayload[];
}

export default VerificationStepsList;
