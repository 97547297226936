import moment from 'moment';
import styled from 'styled-components';
import ILog from "../models/ILog";


const LogItem = (props: ILogItemProps) => {
    const { log } = props;
    console.log('data', log.timestamp)
    return (
        <Container>
            <div style={{ width: '80px' }}>[{log.level?.toUpperCase()}]</div>
            <div style={{ width: '160px' }}>{moment(log.timestamp).format('DD-MMM-yy HH:ss')}</div>
            <LogText>{log.payload}</LogText>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 4px 0px;
    transition: 0.2s;
    :hover {
        background: #e1e1e1;
    }
`

const LogText = styled.div`
    display: flex;
    flex: 1;
    font-size: 14px;
    white-space: break-spaces;
`

export interface ILogItemProps {
    log: ILog
}

export default LogItem