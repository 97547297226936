import { ApartmentOutlined, AuditOutlined, CalendarOutlined, EditOutlined, EyeOutlined, KeyOutlined, MailOutlined, MobileOutlined, NotificationOutlined, NumberOutlined, PhoneOutlined, QrcodeOutlined, ShopOutlined, UserOutlined } from "@ant-design/icons"
import { Button, Statistic, Tag, Tooltip } from "antd"
import _ from "lodash"
import moment from "moment"
import styled from "styled-components"
import { showQrView } from "../../common/QrView"
import metamapIconImage from "../../images/metamap.png"
import { VerificationName } from "../../models/IVerification"
import IUser, { AccountType } from "../../models/User"
import { showSendNotificationDialog } from "../../notification/SendNotificationScreen"
import { showErpForm } from "../ErpForm"
import { showBlockchainData } from "../PublicKeyDialog"
import { showUserFormDialog } from "../UserForm"
import { showUserQr } from "../UserQr"
import { showUserAuditList } from "../user-audit/UserAuditList"
import { showClabeAccounts } from "../ClabeAccountList"

type UserKeyValue = { title: string, property: keyof IUser, icon: React.ReactNode }

const UserInfoComponent = (props: UserInfoComponentProps) => {
    const { user } = props

    const listedProperties: UserKeyValue[] = [
        { title: 'Phone Number', property: 'phoneNumber', icon: <PhoneOutlined /> },
        { title: 'Email', property: 'email', icon: <MailOutlined /> },
        { title: 'Device', property: 'deviceId', icon: <MobileOutlined /> }
    ]

    const renderKeyValue = (item: UserKeyValue) => {
        return <KeyValue key={item.property}>
            <div>{item.icon} {item.title}</div>
            <div>{_.get(user, item.property) || '-'}</div>
        </KeyValue>
    }

    const getVerificationStatus = (verificaion: VerificationName): string | undefined => {
        return user.verifications.find(x => x.name === verificaion)?.status
    }

    return (
        <div>
            <Row>
                <Avatar src={user.image} />
                <div style={{ marginLeft: '24px' }}>
                    <Row>
                        <NameText disabled={!user.name}>
                            {user.name || 'No name'}
                        </NameText>
                        <Tag style={{ height: 'fit-content', marginLeft: '4px' }} >{user.status}</Tag>
                    </Row>
                    <div style={{ fontSize: 13 }}>{user.id}
                        <Tooltip title="Show add account QR">
                            <QrcodeOutlined
                                style={{ margin: '10px 0 0 10px' }}
                                onClick={() => showQrView({ title: 'Account Login QR', content: `{'id': '${user.id}'}` })}
                            />
                        </Tooltip>
                    </div>
                </div>
            </Row>
            <SpacedBetween style={{ marginTop: 24 }}>
                <Statistic
                    title={<div>
                        Account ID
                        <Tooltip title="Show add funds QR">
                            <QrcodeOutlined
                                style={{ margin: '10px 0 0 10px' }}
                                onClick={() => showUserQr({ user })}
                            />
                        </Tooltip>
                    </div>}
                    value={user.accountId || ''}
                    prefix={<NumberOutlined />}
                    valueStyle={{ fontSize: 16 }}
                    groupSeparator=""
                />
                <Statistic title="Account Type" value={user.accountType} prefix={<UserOutlined />} valueStyle={{ fontSize: 16 }} />
                <Statistic title="Created Date" value={moment(user.createdOn).format('YYYY MMM DD')} prefix={<CalendarOutlined />} valueStyle={{ fontSize: 16 }} />
            </SpacedBetween>
            <div style={{ marginTop: 24 }}>
                {listedProperties.map(renderKeyValue)}

                <KeyValue>
                    <div>{<KeyOutlined />} PublicKey</div>
                    <Row>
                        <div style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>{user.publicKey || '-'}</div>
                        <Tooltip title="View blockchain data">
                            <EyeOutlined onClick={() => showBlockchainData(user)} />
                        </Tooltip>
                    </Row>
                </KeyValue>

                <KeyValue>
                    <div>{<NumberOutlined />} ERP</div>
                    <div>
                        {user.erp || '-'}
                        <Tooltip title="Edit ERP mapping">
                            <EditOutlined onClick={() => showErpForm({})} />
                        </Tooltip>
                    </div>
                </KeyValue>
                {user.accountType === AccountType.CORPORATE ? <>
                    {renderKeyValue({ title: 'Company Name', property: 'company.name' as any, icon: <ApartmentOutlined /> })}
                </> : <></>}
                {user.accountType === AccountType.OWNER ? <>
                    {renderKeyValue({ title: 'Store Name', property: 'store.name' as any, icon: <ShopOutlined /> })}
                </> : <></>}
            </div>
            <SpacedBetween style={{ marginTop: 24 }}>
                <Statistic title="Personal Verification" value={getVerificationStatus(VerificationName.PERSONAL_ID)} prefix={<img alt='' src={metamapIconImage} style={{ width: 18, height: 18 }} />} valueStyle={{ fontSize: 14 }} groupSeparator="" />
                <Statistic title="Proof of Residency" value={getVerificationStatus(VerificationName.PROOF_OF_RESIDENCY)} prefix={<img alt='' src={metamapIconImage} style={{ width: 18, height: 18 }} />} valueStyle={{ fontSize: 14 }} groupSeparator="" />
            </SpacedBetween>
            <Row style={{ marginTop: 24 }}>
                <div>
                    <Tag
                        style={{ cursor: 'pointer' }}
                        icon={<NotificationOutlined />}
                        onClick={() => showSendNotificationDialog({ users: [user], initialUsers: [user] })}
                    >
                        Send notification
                    </Tag>
                    <Tag
                        style={{ cursor: 'pointer' }}
                        icon={<AuditOutlined />}
                        onClick={() => showUserAuditList({ userId: user.id })}
                    >
                        View Updates
                    </Tag>
                    <Tag
                        style={{ cursor: 'pointer' }}
                        icon={<AuditOutlined />}
                        onClick={() => showClabeAccounts({ userId: user.id })}
                    >
                        View Clabe Accounts
                    </Tag>
                </div>
                <div style={{ flex: 1 }} />
                <Button onClick={() => showUserFormDialog({ user })} icon={<EditOutlined />}>Edit</Button>
            </Row>
        </div>
    )
}

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const SpacedBetween = styled.div`
    display: flex;
    justify-content: space-between;
`

const KeyValue = styled(SpacedBetween)`
    margin: 10px 0;

    >:nth-child(1) {
        color: grey;
    }
    >:nth-child(2) {
        word-break: keep-all;
        width: 60%;
        text-align: right;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`

const NameText = styled.h2<{ disabled: boolean }>`
    max-width: 80%;
    margin: 0px;
    
    ${props => props.disabled ? `
        color: grey;
        font-style: italic;
    ` : ''};
`

const Avatar = styled.img`
    width: 60px;
    height: 60px;
    border-radius: 100%;
    object-fit: cover;
`

interface UserInfoComponentProps {
    user: IUser
    reload: () => void
}

export default UserInfoComponent
