import { collection, getFirestore, onSnapshot, orderBy, query } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Scaffold from "../components/Scaffold";
import Titlebar from "../components/Titlebar";
import ILog, { LogGroup } from "../models/ILog";
import LogItem from "./LogItem";
import { Tag } from 'antd';

const logGoups = [
    { name: 'WHATCH-DOG', value: 'logs' },
    { name: 'PAYCASH', value: 'paycash' },
    { name: 'REGISTRATION', value: 'registrations' },
]

export default function LogsScreen() {
    const [allLogs, setAllLogs] = useState<ILog[]>([])
    const [selectedGroup, setSelectedGroup] = useState<LogGroup | undefined>('registrations');

    const data = useMemo(() => {
        if (!selectedGroup) return allLogs
        return allLogs.filter(x => x.group === selectedGroup)
    }, [allLogs, selectedGroup])

    const handleChange = (tag: LogGroup | undefined, checked: boolean) => {
        setSelectedGroup(checked ? tag : undefined)
    }

    useEffect(() => {
        const db = getFirestore((window as any).app)
        const col = collection(db, "logs")
        const q = query(col, orderBy("timestamp", "desc"));
        const unsub = onSnapshot(q, (snapshot) => {
            const logs = snapshot.docs.map(x => {
                const log = x.data() as ILog
                log.timestamp = (log as any).timestamp.toDate()
                return log
            })
            setAllLogs(logs)
        })
        return () => unsub()
    }, [])

    return (
        <Scaffold>
            <Titlebar title='Logs' />
            <CategoriesContainer>
                <div style={{ marginRight: '10px' }}>Categories: </div>
                {logGoups.map<React.ReactNode>((item) => (
                    <Tag.CheckableTag
                        key={item.value}
                        checked={selectedGroup === item.value}
                        onChange={(checked) => handleChange(item.value as LogGroup, checked)}
                    >
                        {item.name.toUpperCase()}
                    </Tag.CheckableTag>
                ))}
                <Tag.CheckableTag
                    key='all'
                    checked={!selectedGroup}
                    onChange={(checked) => handleChange(undefined, checked)}
                >
                    ALL
                </Tag.CheckableTag>
            </CategoriesContainer>
            <Container>
                {data.map(item => {
                    return <LogItem log={item} />
                })}
            </Container>
        </Scaffold>
    )
}

const Container = styled.div`
    height: 100%;
    padding: 20px;
    overflow: scroll;
`

const CategoriesContainer = styled.div`
    width: 300px;
    padding: 8px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
`