import { RedoOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, message, Popconfirm, Table } from "antd";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import Scaffold from "../components/Scaffold";
import Titlebar from "../components/Titlebar";
import { completeRecharge, getRechargeRequests } from "../data/EkisClient";
import { IPaycashPaymentStatusData } from "../models/IPaycashWebhookPayload";
import IRechargeRequest from "../models/IRechargeRequest";

export default function RechargeScreen() {
  const [loading, setLoading] = useState(false)
  const [refreshing, setRefreshing] = useState(false)
  const [data, setData] = useState<IRechargeRequest[]>([])

  useEffect(() => {
    setLoading(true)
    getRechargeRequests()
      .then(requests => {
        setData(requests)
        setLoading(false)
      })
      .catch(e => {
        console.error(e)
        message.error('Unable to load data')
        setLoading(false)
      })
  }, [])

  const refreshData = async () => {
    setRefreshing(true)
    try {
      const data = await getRechargeRequests()
      setData(data)
      message.success('Data refreshed')
    } catch (e) {
      message.error((e as any).message)
      console.error(e)
    }
    setRefreshing(false)
  }

  const renderActions = (item: IRechargeRequest) => {
    const menu = (
      <Menu>
        {item.paycashPayload ? (
          <Menu.Item key="retry-recharge" icon={<RedoOutlined />}>
            <Popconfirm
              title="Are you sure you want to redo this recharge?"
              onConfirm={() => retryRecharge(item.paycashPayload!)}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              Retry Recharge
            </Popconfirm>
          </Menu.Item>
        ) : null}
      </Menu>
    );
    return (
      <Dropdown overlay={menu}>
        <Button>Actions</Button>
      </Dropdown>
    )
  }

  const retryRecharge = async (payload: IPaycashPaymentStatusData) => {
    try {
      await completeRecharge(payload)
      message.success('Completed')
      await refreshData()
    } catch (e) {
      console.error(e)
      message.error((e as any).msg || 'Failed')
    }
  }

  const columns = [
    {
      title: 'Paycash Ref',
      dataIndex: 'paycashReference'
    },
    {
      title: 'AccountID',
      render: (item: IRechargeRequest) => item.user?.accountId
    },
    {
      title: 'Name',
      render: (item: IRechargeRequest) => item.user?.name
    },
    {
      title: 'Recharge amount',
      dataIndex: 'amount',
    },
    {
      title: 'Store Fee',
      dataIndex: 'storeFee',
    },
    {
      title: 'Service Fee',
      dataIndex: 'serviceFee',
    },
    {
      title: 'Total Amount',
      dataIndex: 'finalAmount',
    },
    {
      title: 'Store',
      render: (item: IRechargeRequest) => item.store.name,
    },
    {
      title: 'Created On',
      dataIndex: 'createdOn',
      render: (date: string) => {
        return <div>{moment(date).format('MMMM DD, yyyy hh:mm')}</div>
      },
      sorter: (a: IRechargeRequest, b: IRechargeRequest) => {
        if (!a.createdOn) return -1
        if (!b.createdOn) return 1
        return moment(a.createdOn).valueOf() - moment(b.createdOn).valueOf()
      },
      defaultSortOrder: 'descend' as any,
    },
    {
      title: 'Expires On',
      dataIndex: 'expiresOn',
      render: (date: string) => {
        return <div>{moment(date).format('MMMM DD, yyyy hh:mm')}</div>
      },
      sorter: (a: IRechargeRequest, b: IRechargeRequest) => {
        if (!a.expiresOn) return -1
        if (!b.expiresOn) return 1
        return moment(a.expiresOn).valueOf() - moment(b.expiresOn).valueOf()
      }
    },
    {
      title: 'PbtxResult',
      dataIndex: 'pbtxResult'
    },
    {
      title: 'PaycashResult',
      render: (item: IRechargeRequest) => _.get(item, 'paycashPayload.Resultado', '')
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: (a: IRechargeRequest, b: IRechargeRequest) => a.status.localeCompare(b.status),
    },
    {
      title: 'Actions',
      render: renderActions
    },
  ];

  return (
    <Scaffold>
      <Titlebar title='Recharge Requests' />
      <div style={styles.row}>
        <Button
          key='refresh'
          style={{ marginRight: 10 }}
          onClick={refreshData}
          loading={refreshing}
        >
          Refresh
        </Button>
      </div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data}
        rowKey='id'
        pagination={false}
      />
    </Scaffold>
  )
}

const styles = {
  row: {
    display: 'flex',
    padding: 10,
    justifyContent: 'flex-end'
  }
}
