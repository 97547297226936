import { Alert, message, Modal, Spin } from 'antd'
import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { formatPublicKey } from '../common/util'
import { getPublicKey } from '../data/EkisClient'
import IUser from "../models/User"

export const PublicKeyDialog = (props: IPublicKeyDialogProps) => {
    const [loading, setLoading] = useState(false)
    const [blockchainKey, setBlockchainKey] = useState('')

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        try {
            setLoading(true)
            const publickey = await getPublicKey(props.user.accountId!)
            setBlockchainKey(publickey)
        } catch (error) {
            const e = error as any
            message.error(e.msg || e.message || 'Something went wrong')
        }
        setLoading(false)
    }
    const userPublicKey = formatPublicKey(props.user.publicKey)
    const blockchainPublicKey = formatPublicKey(blockchainKey)
    return (
        <Spin spinning={loading}>
            <div>
                {!loading && (
                    blockchainPublicKey !== userPublicKey
                        ? <Alert showIcon message="Blockchain public key and client public key do not match. Keyswap is required" type="warning" />
                        : <Alert showIcon message="Blockchain public key and client public are the same" type="success" />
                )}
                <div style={{ marginTop: 8 }}>Blockchain:</div>
                <div style={{ marginBottom: 16 }}>{blockchainPublicKey}</div>
                <div>UMS:</div>
                <div>{userPublicKey}</div>
            </div>
        </Spin>
    )
}

interface IPublicKeyDialogProps {
    user: IUser
}

export function showBlockchainData(user: IUser) {
    return new Promise<void>((resolve, reject) => {
        const mountTarget = document.createElement('div')
        document.body.appendChild(mountTarget)

        function callback() {
            resolve()
            ReactDOM.unmountComponentAtNode(mountTarget)
            mountTarget.parentElement?.removeChild(mountTarget)
        }

        ReactDOM.render(
            <Modal
                title='Blockchain Data'
                visible={true}
                footer={null}
                onCancel={() => callback()}
            >
                <PublicKeyDialog user={user} />
            </Modal>,
            mountTarget
        )
    })
}
