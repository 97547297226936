export enum Env {
  DEV = 'dev',
  STAGING = 'stage',
  PILOT = 'pilot',
  TEST = 'test'
}


export enum EkisEnv {
  DEV,
  PILOT,
  TEST,
  STAGING,
  DEV_LOCAL,
  PILOT_LOCAL,
  STAGING_LOCAL
}

export function getEkisEnv(): EkisEnv {
  const host = window.location.host
  if (host.includes('ums-stage') || host.includes('ekis-stag')) {
    return EkisEnv.STAGING
  }
  if (host.includes('ekis-pilot') || host.includes('ums.ekis.io') || host.includes('ums.ekis.io')) {
    return EkisEnv.PILOT
  }
  if (host.includes('ekis-dev') || host.includes('dev.ekis.io') || host.includes('ums-dev.ekis.io')) {
    return EkisEnv.DEV
  }
  if (host.includes('ekis-test')) {
    return EkisEnv.TEST
  }
  if (host.includes('localhost')) {
    return EkisEnv.DEV
  }
  return EkisEnv.DEV
}

export function getGeneralEnv(): Env {
  switch (getEkisEnv()) {
    case EkisEnv.DEV:
    case EkisEnv.DEV_LOCAL:
      return Env.DEV
    case EkisEnv.STAGING:
    case EkisEnv.STAGING_LOCAL:
      return Env.STAGING
    case EkisEnv.PILOT:
    case EkisEnv.PILOT_LOCAL:
      return Env.PILOT
    case EkisEnv.TEST:
      return Env.TEST
  }
}

export function domainCheck() {
  const host = window.location.host
  if (host === 'api-dev.ekis.io') {
    window.location.href = 'https://ums-dev.ekis.io'
  }
  if (host === 'api.ekis.io') {
    window.location.href = 'https://ums.ekis.io'
  }
  if (host === 'api-stage.ekis.io') {
    window.location.href = 'https://ums-stage.ekis.io'
  }
}