import { Button, Form, Input } from 'antd'
import { CSSProperties, useEffect, useState } from 'react'

const RESEND_WAIT = 30

function OtpForm(props: IOtpFormProps) {
  const [resendCountdown, setResendCountdown] = useState<number>()

  function onSubmit(values: any) {
    props.onSubmit(values.otp)
  }

  useEffect(() => {
    startResendCountDown(RESEND_WAIT)
  }, [])

  function startResendCountDown(count: number) {
    setResendCountdown(count)
    let interval = setInterval(() => {
      setResendCountdown(c => {
        if (c !== undefined && c <= 0) {
          clearInterval(interval)
          return undefined
        }
        return c!! - 1
      })
    }, 1000)
  }

  return (
    <Form layout='vertical' onFinish={onSubmit}>
      <Form.Item
        label="OTP"
        name="otp"
        help={`An OTP has been sent to your phone number ${props.phoneNumber}. Please enter it here`}
      >
        <Input autoFocus />
      </Form.Item>
      <div style={styles.center}>
        <Button
          type="link"
          disabled={resendCountdown !== undefined}
          onClick={async () => {
            await props.onResendOtp()
            startResendCountDown(RESEND_WAIT)
          }}
        >
          Resend OTP {resendCountdown !== undefined ? `(${resendCountdown})` : ''}
        </Button>
      </div>
      <div style={styles.center}>
        <Form.Item style={{ marginTop: 10 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </div>
    </Form>
  )
}

interface IOtpFormProps {
  phoneNumber: string
  onSubmit: (otp: string) => void
  onResendOtp: () => Promise<any>
}

const styles: { [key: string]: CSSProperties } = {
  center: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center'
  }
}

export default OtpForm
