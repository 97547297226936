export function isLocal() {
  return window.location.href.includes('localhost')
}

export function isProduction() {
  return window.location.href.includes('ekis-ee7bb')
}

export function toQuery(json: { [key: string]: any }) {
  return '?' +
    Object.keys(json)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(json[key]))
      .join('&');
}

export function fixWithoutRounding(num: number, decimals: number): string {
  console.log(num)
  const [whole, fraction = ''] = num.toString().split('.')
  const truncated = fraction.slice(0, decimals).padEnd(decimals, '0')
  return `${whole}.${truncated}`
}

// 1222 is the weight part of the public key. The rest of the data is what we need
export function formatPublicKey(publicKey?: string) {
  if (!publicKey) return publicKey
  if (publicKey.startsWith('1222')) {
    return publicKey.substring(4)
  }
  return publicKey
}