import { Modal, Tooltip, Typography } from "antd";
import moment from "moment";
import { CSSProperties, useState } from "react";
import ReactJson from "react-json-view";
import ITransaction, { TxType } from "../models/Transaction";

const { Text } = Typography

export default function TransactionItem(props: ITransactionItemProps) {
  const { tx } = props
  const actor = tx.type === TxType.INCOMING ? tx.sender.name : tx.receiver.name
  const [isDataShown, setDataShown] = useState(false)

  return (
    <div style={styles.container}>
      <div style={styles.row}>
        <caption>{moment(props.tx.timestamp).format('YYYY-MMM-DD')}</caption>
        <Tooltip title={props.tx.txhash}>
          {/* eslint-disable-next-line */}
          <a onClick={() => tx.rawData && setDataShown(true)}>#</a>
        </Tooltip>
      </div>
      <div style={styles.row}>
        <div style={{ marginRight: 12 }}>{actor}</div>
        <Tooltip title={props.tx?.rawData?.amount}>
          <b style={{ color: props.tx.type === TxType.INCOMING ? 'green' : 'crimson' }}>
            {props.tx.amount}
          </b>
        </Tooltip>
      </div>
      {(props.tx.totalFeeAmount || props.tx.feeMemo) ? (
        <div style={{ ...styles.row, justifyContent: 'flex-end' }}>
          <Tooltip title={props.tx.feeMemo}>
            <Text type="secondary" style={{ fontSize: '12px' }}>{props.tx.totalFeeAmount}</Text>
          </Tooltip>
        </div>
      ) : null}
      <Modal
        title="Transaction Data"
        visible={isDataShown}
        onCancel={() => setDataShown(false)}
        cancelText="Close"
        okButtonProps={{ style: { display: "none" } }}
        centered
        width='90vw'
        style={{ maxWidth: 1000 }}
      >
        <div style={{ height: '70vh', width: '100%', overflow: 'auto' }}>
          <ReactJson src={tx.rawData} />
        </div>
      </Modal>
    </div>
  )
}

interface ITransactionItemProps {
  tx: ITransaction
}

const styles: { [key: string]: CSSProperties } = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}
