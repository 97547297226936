import { User } from "firebase/auth"
import { CSSProperties } from "react"

export default function UserCard(props: UserCardProps) {
  return (
    <div style={styles.container}>
      <img src={props.user.photoURL!} alt='' style={styles.avatar} />
      <div style={styles.column}>
        <div>{props.user.displayName}</div>
        <div>{props.user.email}</div>
      </div>
    </div>
  )
}

interface UserCardProps {
  user: User
}

const styles: { [key: string]: CSSProperties } = {
  container: {
    display: 'flex',
    flexDirection: 'row'
  },
  column: {
    display: 'flex',
    flexDirection: 'column'
  },
  avatar: {
    width: 40,
    height: 40,
    objectFit: 'contain',
    borderRadius: '100%',
    marginRight: 10
  }
}