import { onAuthStateChanged, User } from 'firebase/auth'
import { CSSProperties, useEffect, useState } from 'react'
import { getFirebaseAuth } from '../firebase-config'
import UserCard from './UserCard'


export default function Titlebar(props: TitlebarProps) {
  const [user, setUser] = useState<User>()

  useEffect(() => {
    const auth = getFirebaseAuth()
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user)
      }
    })
  }, [])

  return (
    <div style={styles.container}>
      <div style={styles.title}>{props.title}</div>
      {user ? <UserCard user={user} /> : null}
    </div>
  )
}

interface TitlebarProps {
  title: string
}

const styles: { [key: string]: CSSProperties } = {
  container: {
    width: '100%',
    minHeight: 56,
    padding: '8px 16px',
    color: 'white',
    background: 'var(--secondary-color)',
    display: 'flex',
    alignItems: 'space-between',
    justifyContent: 'space-between'
  },
  title: {
    color: 'white',
    fontSize: 24
  }
}