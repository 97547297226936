import { Modal } from 'antd'
import ReactDOM from 'react-dom'
import { QRCode } from 'react-qrcode-logo'
import EkisLogo from '../images/ekis_logo.png'

export default function QrView(props: QrViewProps) {
  return (
    <div className='column'>
      <div style={{ alignSelf: 'center' }}>
        <QRCode
          logoImage={EkisLogo}
          value={props.content}
          size={250}
          logoHeight={70}
          logoWidth={70}
        />
      </div>
    </div>
  )
}

export function showQrView(props: QrViewProps & { title?: string }) {
  const mountTarget = document.createElement('div')
  document.body.appendChild(mountTarget)

  const callback = () => {
    ReactDOM.unmountComponentAtNode(mountTarget)
    mountTarget.parentElement?.removeChild(mountTarget)
  }

  ReactDOM.render(
    <Modal title={props.title || 'Scan QR'} visible={true} onOk={callback} okText='Close' onCancel={callback}>
      <QrView {...props} />
    </Modal>,
    mountTarget
  )
}

interface QrViewProps {
  content: string
}