import { FirebaseApp, initializeApp } from 'firebase/app';
import { Auth, getAuth, RecaptchaVerifier } from 'firebase/auth';
import { EkisEnv, getEkisEnv } from './config';

let app: FirebaseApp | undefined
let auth: Auth | undefined

function getFirebaseConfig() {

  switch (getEkisEnv()) {
    case EkisEnv.DEV:
    case EkisEnv.DEV_LOCAL:
      return {
        apiKey: "AIzaSyBUyees0d_rIgLXwaI11iq88-9mZ7BYbgw",
        authDomain: "ekis-dev.firebaseapp.com",
        projectId: "ekis-dev",
        storageBucket: "ekis-dev.appspot.com",
        messagingSenderId: "86105535400",
        appId: "1:86105535400:web:70f1c796b7b164fb89639d",
        measurementId: "G-ZWPQ4B6N9E"
      }
    case EkisEnv.STAGING:
    case EkisEnv.STAGING_LOCAL:
      return {
        apiKey: "AIzaSyDFFIKcKtT-0XwQfZ33Z-a0pfsW9_Y9Tb4",
        authDomain: "ekis-stag.firebaseapp.com",
        projectId: "ekis-stag",
        storageBucket: "ekis-stag.appspot.com",
        messagingSenderId: "778487686997",
        appId: "1:778487686997:web:1f262a7cb37777e49bb1dc",
        measurementId: "G-R1C4CQ6H1P"
      }
    case EkisEnv.PILOT:
    case EkisEnv.PILOT_LOCAL:
      return {
        apiKey: "AIzaSyDp3-BPr8f_GJvrMt76TpcWpiJwLRpBNyw",
        authDomain: "ekis-pilot.firebaseapp.com",
        projectId: "ekis-pilot",
        storageBucket: "ekis-pilot.appspot.com",
        messagingSenderId: "446622372035",
        appId: "1:446622372035:web:1bca533ad7c3456b61d276",
        measurementId: "G-SYP36ZSFX2"
      }
    default:
      return {
        apiKey: "AIzaSyDp3-BPr8f_GJvrMt76TpcWpiJwLRpBNyw",
        authDomain: "ekis-pilot.firebaseapp.com",
        projectId: "ekis-pilot",
        storageBucket: "ekis-pilot.appspot.com",
        messagingSenderId: "446622372035",
        appId: "1:446622372035:web:1bca533ad7c3456b61d276",
        measurementId: "G-SYP36ZSFX2"
      }
  }
}

export function initializeFirebase() {
  if (!app) {
    app = initializeApp(getFirebaseConfig());
    (window as any).app = app
  }
}

export function getFirebaseApp(): FirebaseApp {
  if (!app) {
    throw new Error("you need to call 'initializeFirebase' first")
  }
  return app
}

export function getFirebaseAuth() {
  if (!auth) {
    auth = getAuth(getFirebaseApp());
  }
  return auth
}

export function getRecaptchaVerifier(buttonId: string, callback: Function) {
  const paramters = {
    size: 'invisible',
    callback: callback
  }
  return new RecaptchaVerifier(buttonId, paramters, getAuth());
}
