import { Card } from 'antd';
import { CSSProperties } from 'react';
import EkisLogo from './images/ekis-logo-text.svg';
import Wave from './images/wave.svg';

function UserApproved() {
  return (
    <div style={styles.center}>
      <Card bodyStyle={styles.cardBody} style={styles.card}>
        <img style={{ width: 150 }} src={EkisLogo} alt='' />
        <div style={styles.text}>User Approved</div>
        <div>✅ The user has been granted access to ekis UMS</div>
      </Card>
    </div>
  )
}

const styles: { [key: string]: CSSProperties } = {
  google: { width: 20, height: 20, marginRight: 10 },
  center: {
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    background: `url(${Wave})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  card: {
    borderRadius: '10px',
    overflow: 'hidden',
    background: '#F2F2F2',
    width: 440,
    minHeight: 250,
    // aspectRatio: '16 / 9'
  },
  cardBody: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    padding: 30,
  },
  text: {
    fontSize: 24,
    fontWeight: 'normal',
    margin: '20px 0'
  }
}

export default UserApproved
