import { UploadOutlined } from "@ant-design/icons"
import { Button, Divider, Skeleton, message } from "antd"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import Scaffold from "../../components/Scaffold"
import Titlebar from "../../components/Titlebar"
import { getUser } from "../../data/EkisClient"
import IUser from "../../models/User"
import TransactionList from "../../transactions/TransactionList"
import { showUserTxExportDialog } from "../UserTxExport"
import UserInfoComponent from "./UserInfoComponent"
import UserVerificationsComponent from "./UserVerificationsComponent"

const UserDetailScreen = (props: UserDetailScreenProps) => {
    const params = useParams()
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState<IUser>()

    console.log(loading)

    const fetchUser = async (userId: string) => {
        try {
            setLoading(true)
            const reuslt = await getUser(userId)
            setUser(reuslt)
        } catch (e) {
            message.error(e)
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchUser(params.userId!)
    }, [])

    return (
        <div style={{ backgroundColor: '#f6fbff' }}>
            <Scaffold>
                <Titlebar title='detalle del usuario' />
                <Root style={{ whiteSpace: 'break-spaces' }}>
                    <Card>
                        {user ? <UserInfoComponent user={user} reload={() => { }} /> : <Skeleton />}
                    </Card>
                    <Card>
                        <SpaceBetween>
                            <h3>Transactions</h3>
                            <Button
                                type="ghost"
                                size='small'
                                icon={<UploadOutlined />}
                                onClick={() => showUserTxExportDialog({ user: user! })}
                            >
                                Export
                            </Button>
                        </SpaceBetween>
                        <Divider />
                        {user ? <TransactionList user={user} /> : <Skeleton />}
                    </Card>
                    <Card>
                        {user ? <UserVerificationsComponent userId={user.id} /> : <Skeleton />}
                    </Card>
                </Root>
            </Scaffold>
        </div>
    )
}

const Root = styled.div`
    height: 100%;
    display: grid;
    padding: 20px;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 20px;

`

const Card = styled.div`
    display: flex;
    flex-direction: column;
    height: 50vh;
    max-height: 600px;
    max-width: 90vw;
    background: white;
    padding: 24px;
    overflow: auto;
`

const SpaceBetween = styled.div`
    display: flex;
    justify-content: space-between;
`

interface UserDetailScreenProps {

}

export default UserDetailScreen