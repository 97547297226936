import IUserCompany from "./IUserCompany"
import { IVerification } from "./IVerification"

export enum UserRole {
  USER = 'USER',
  MERCHANT = 'MERCHANT'
}

export default interface IUser {
  id: string
  accountId?: string
  publicKey?: string
  name: string
  email: string
  balance: number
  image: string
  role: UserRole
  createdOn: string
  deviceId?: string
  fcmToken?: string
  status: UserStatus
  recoveryStatus: RecoveryStatus
  // not applicable for merchant users
  dob: Date
  accountType: AccountType
  verifications: IVerification[]
  phoneNumber?: string
  company?: IUserCompany
  erp?: string
  store?: { name: string } // only for OWNER accountType
}

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  INCOMPLETE = 'INCOMPLETE',
  DISABLED = 'DISABLED'
}

export enum RecoveryStatus {
  UNINITIALIZED = 'UNINITIALIZED',
  INITIALIZED = 'INITIALIZED',
  DOCUMENT_MISMATCH = 'DOCUMENT_MISMATCH',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED'
}

export enum AccountType {
  PERSONAL = 'PERSONAL',
  CORPORATE = 'CORPORATE',
  OWNER = 'OWNER'
}
