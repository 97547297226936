import { HomeOutlined, IdcardOutlined } from '@ant-design/icons'
import { Button, Collapse, List, message, Modal, Radio, Tag, Tooltip, Typography } from 'antd'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import Scaffold from '../components/Scaffold'
import StatsRow from '../components/StatsRow'
import Titlebar from '../components/Titlebar'
import { getVerifications } from '../data/EkisClient'
import metamapIconImage from '../images/metamap.png'
import IFlow from '../models/IFlow'
import IMetamapPayload from '../models/IMetamapPayload'
import IStat from '../models/IStat'
import { VerificationStatus } from '../models/IVerification'
import IUserVerifications from './IUserVerifications'
import { buildUserVerifications, filterUserVerifications, getFlowName, getNamesFromFLows, metamapDashboardUrl } from './verifications-helper'
import VerificationStepsList, { showVerificationStepsList } from './VerificationStepsList'

const { Panel } = Collapse
const { Text } = Typography

const UserVerificationsScreen = () => {
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)
  const [statusFilter, setStatusFilter] = useState<VerificationStatus | 'expired' | 'all'>('all')
  const [steps, setSteps] = useState<IMetamapPayload[]>([])
  const [selected, setSelected] = useState<IFlow>()
  const [refreshing, setRefreshing] = useState(false)

  const data = useMemo(() => buildUserVerifications(steps), [steps])

  const filteredData = useMemo(() => {
    if (statusFilter === 'all') return []
    return filterUserVerifications(data, statusFilter)
  }, [statusFilter])

  const stats: IStat[] = useMemo(() => {
    const allVerifications: IStat = {
      name: 'Verificaciones totales',
      value: 0,
      icon: 'verifications',
    }
    const successfulVerifications: IStat = {
      name: 'Verificaciones completas',
      value: 0,
      icon: 'verification_success',
    }
    const failedVerifications: IStat = {
      name: 'Verificaciones incompletas',
      value: 0,
      icon: 'verification_failed',
    }
    for (const x of steps) {
      if (x.status) {
        allVerifications.value += 1
      }
      switch (x.status) {
        case 'verified':
          successfulVerifications.value += 1
          break;
        case 'rejected':
          failedVerifications.value += 1
          break;
      }
    }
    return [allVerifications, successfulVerifications, failedVerifications]
  }, [steps])

  const getActiveKeyProps = () => {
    return statusFilter === 'all'
      ? {}
      : { activeKey: filteredData.map(x => x.userId) }
  }

  useEffect(() => {
    setLoading(true)
    getVerifications()
      .then(steps => {
        setSteps(steps)
        setLoading(false)
      })
      .catch(error => {
        message.error('Unable to load data')
        setLoading(false)
      })
  }, [])

  const refreshData = async () => {
    setRefreshing(true)
    try {
      const steps = await getVerifications()
      setSteps(steps)
      message.success('Data refreshed')
    } catch (e) {
      message.error((e as any).message)
      console.error(e)
    }
    setRefreshing(false)
  }

  return (
    <Scaffold>
      <Titlebar title='Verificaciones' />
      <StatsRow stats={stats} />
      <Container>
        <Row style={{
          alignItems: 'center',
          justifyContent: 'flex-end',
          marginBottom: 10
        }}>
          <Button
            key='refresh'
            style={{ marginRight: 10 }}
            onClick={refreshData}
            loading={refreshing}
          >
            Refresh
          </Button>
          <Radio.Group
            key='1'
            buttonStyle="solid"
            value={statusFilter}
            onChange={e => setStatusFilter(e.target.value)}
          >
            <Radio.Button value="all">All</Radio.Button>
            <Radio.Button value="verified">Verified</Radio.Button>
            <Radio.Button value="reviewNeeded">Review needed</Radio.Button>
            <Radio.Button value="rejected">Rejected</Radio.Button>
            <Radio.Button value="expired">Expired</Radio.Button>
          </Radio.Group>
        </Row>
        <Collapse bordered={true} {...getActiveKeyProps()}>
          {(statusFilter === 'all' ? data : filteredData).map(item => {
            const flows = item.flows.sort((a, b) => b.date.valueOf() - a.date.valueOf())
            return (
              <Panel
                style={{ background: 'white' }}
                key={item.userId}
                showArrow={false}
                header={<UserVerificationItem {...item} />}
                extra={<UserVerificationStatus {...item} />}
              >
                <List
                  size='small'
                  dataSource={flows}
                  renderItem={flow => <FlowItem {...flow} />}
                />
              </Panel>
            )
          })}
        </Collapse>
      </Container>
      <Modal
        title='Details'
        visible={!!selected}
        onCancel={() => setSelected(undefined)}
        cancelText='Close'
        okButtonProps={{ style: { display: 'none' } }}
        width={'90vw'}
      >
        {selected ? <VerificationStepsList data={selected.steps} /> : null}
      </Modal>
    </Scaffold >
  )
}

export const UserVerificationItem = (item: IUserVerifications) => {
  const names = getNamesFromFLows(item.flows)
  const isNameMismatch = (
    names.personalId !== undefined &&
    names.address !== undefined &&
    names.personalId !== names.address
  )


  const nameMismatchTag = isNameMismatch
    ? (
      <Tooltip
        title={<div style={{ whiteSpace: 'break-spaces' }}>
          {`Personal ID: ${names.personalId}\nAddress: ${names.address}`}
        </div>}>
        <Tag style={{ marginLeft: 10 }} color="warning">Name mismatch</Tag>
      </Tooltip>
    )
    : null

  const name = item.name
    ? (<Text>{item.name} {nameMismatchTag} <UserId>{item.userId}</UserId></Text>)
    : item.userId


  return (
    <List.Item.Meta
      title={<Text>{name}</Text>}
      description={`${moment(item.latestVerificationDate).format('DD MMM yyyy hh:mm')} - ${item.flows.length} verifications`}
    />
  )
}

export const UserVerificationStatus = (item: IUserVerifications) => {
  return (
    <Row>
      <Tooltip title={item.personalIdVerification}>
        <IdcardOutlined
          style={{
            fontSize: 22,
            color: item.isPersonalIdVerified ? '#4be74b' : 'lightgrey'
          }}
        />
      </Tooltip>
      <Tooltip title={item.addressVerification}>
        <HomeOutlined
          style={{
            fontSize: 22,
            color: item.isAddressVerified ? '#4be74b' : 'lightgrey'
          }}
        />
      </Tooltip>
    </Row>
  )
}

export const FlowItem = (flow: IFlow) => {
  const name = getFlowName(flow.flowId)
  let color
  switch (flow.status) {
    case 'verified':
      color = 'green'
      break;
    case 'rejected':
      color = 'error'
      break;
    case 'reviewNeeded':
      color = 'warning'
      break;
    case 'expired':
      color = 'default'
      break;
    default:
      break;
  }
  return (
    <List.Item>
      <List.Item.Meta
        title={name}
        description={moment(flow.date).format('DD MMM yyyy hh:mm')}
      />
      <div>
        <Tag color={color}>{flow.status}</Tag>
        <a target='_black' href={metamapDashboardUrl(flow.verificationId)}>
          <img alt='' src={metamapIconImage} style={{ width: 18, height: 18 }} />
        </a>
        <Button type='link' onClick={() => showVerificationStepsList({ data: flow.steps })}>
          Details
        </Button>
      </div>
    </List.Item>
  )
}

const Container = styled.div`
  margin: 50px;
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & * {
    margin: 0 4px;
  }
`

const UserId = styled.div`
  color: #b7b7b7;
  font-size: 0.9em;
`

export default UserVerificationsScreen
