import { getFirebaseAuth } from "../firebase-config"

export default class HttpClient {
  host: string
  headers: Headers = {}

  constructor(host: string) {
    this.host = host
  }

  get = (path: string) => this.call('get', path)

  post = (path: string, body?: any, options?: RequestInit) => {
    return this.call('post', path, body, options)
  }

  put = (path: string, body?: any, options?: RequestInit) => {
    return this.call('put', path, body, options)
  }

  delete = (path: string) => this.call('delete', path)

  private call = (method: string, path: string, body?: any, options?: RequestInit) => {
    const url = this.host + path
    if (!options) {
      options = {}
    }
    options.method = method
    if (!options.headers) {
      options.headers = {}
    }
    options.headers = {
      'Content-Type': 'application/json',
      ...options.headers,
      ...this.headers
    }
    if (body) {
      options.body = typeof body === 'string' ? body : JSON.stringify(body)
    }
    return fetch(url, options).then(async res => {
      let body = undefined
      const responseContent = await res.text()
      try {
        body = JSON.parse(responseContent)  
      } catch (e) {
        body = responseContent
      }

      if (!res.ok) {
        if (res.status === 401) {
          getFirebaseAuth().signOut()
          window.location.href = '/login'
          return
        }
        const result = body?.msg ? body : { msg: res.statusText }
        return Promise.reject(result)
      }
      return body
    })
  }
}

type Headers = { [key: string]: string }