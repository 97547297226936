import { Button, Card, Form, Input, message, Row, Select } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import Scaffold from '../components/Scaffold'
import { getUsers, recoverAccount } from '../data/EkisClient'
import IUser from '../models/User'


const DevScreen = () => {
  const [loading, setLoading] = useState(false)
  const [fetchingUsers, setFetchingUsers] = useState(false)
  const [users, setUsers] = useState<IUser[]>([])
  const [form] = Form.useForm();

  useEffect(() => {
    setFetchingUsers(true)
    getUsers()
      .then(users => {
        const data = users
          .filter(x => !!x.accountId && !!x.publicKey)
          .sort((u1, u2) => {
            const d1 = moment(u1.createdOn)
            const d2 = moment(u2.createdOn)
            return d2.valueOf() - d1.valueOf()
          })
          .map(u => {
            return { ...u, name: `${u.name} (${u.accountId})` }
          })
        setUsers(data)
        setFetchingUsers(false)
      })
      .catch(error => {
        message.error('Unable to load users')
        setFetchingUsers(false)
      })
  }, [])

  async function handleSubmit(values: any) {
    const { accountId, publicKey } = values
    try {
      setLoading(true)
      await recoverAccount(accountId, publicKey)
      message.success('Account updated')
    } catch (e) {
      message.error((e as any).msg || 'Recovery failed')
      console.error(e);
    }
    setLoading(false)
  }

  const handleUserSelect = (index: any) => {
    const user = users[index]
    console.log(user)
    form.setFieldsValue({
      accountId: user.accountId
    })
  }

  return (
    <Scaffold>
      <Card style={{ padding: 20, margin: '40px auto', width: 500 }}>
        <h2>Account Recovery</h2>
        <div>Select user</div>
        <Select
          showSearch
          onSelect={handleUserSelect}
          style={{ width: '100%', height: 'fit-content', marginBottom: 30 }}
          loading={fetchingUsers}
          filterOption={(input, option) => {
            input = input.toLowerCase()
            const index = (option as any).value
            const user = users[index]
            return user.name.toLowerCase().includes(input) || user.id.includes(input)
          }}
        >
          {users.map((user, index) => <Select.Option key={user.id} value={index}>
            <div>
              <div>{user.name}</div>
              <div style={{ color: '#bbbbbb' }}>{moment(user.createdOn).format('DD MMM yyyy hh:ss')}</div>
            </div>
          </Select.Option>)}
        </Select>
        <Form
          form={form}
          layout='vertical'
          onFinish={handleSubmit}
        >

          <Form.Item
            name='accountId'
            label='Account ID'
            required
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='publicKey'
            label='Public Key'
            required
          >
            <Input />
          </Form.Item>
          <Row align='bottom' justify='end'>
            <Form.Item>
              <Button loading={loading} type="primary" htmlType="submit">
                Recover
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Card>
    </Scaffold >
  )
}

export default DevScreen