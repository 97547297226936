import ReactDOM from 'react-dom'
import { message, Modal, Table } from 'antd'
import { useEffect, useState } from 'react'
import { getClabeAccounts } from '../data/EkisClient'

const ClabeAccountList = (props: ClabeAccountListProps) => {
    const [data, setUpdates] = useState<any[]>([])
    const [loading, setLoading] = useState(false)
  
    const fetchData = () => {
      setLoading(true)
      getClabeAccounts(props.userId)
        .then(result => {
          setUpdates(result)
          setLoading(false)
        })
        .catch(e => {
          message.error(e.msg || 'Something went wrong')
          setLoading(false)
        })
    }
  
    useEffect(() => fetchData(), [])
  
    return (
      <Table
        loading={loading}
        size='small'
        pagination={{ hideOnSinglePage: true, pageSize: 100 }}
        dataSource={data}
        columns={[
          {
            dataIndex: 'accountNumber',
            width: 100,
            title: 'CLABE account number'
          }, {
            dataIndex: 'alias',
            width: 150,
            title: 'Alias'
          }, {
            dataIndex: 'isDefault',
            width: 110,
            title: 'Is Default',
            render: (value) => value ? 'Yes' : 'No'
          },
        ]}
      />
    )
  }

export function showClabeAccounts(props: ClabeAccountListProps) {
    const mountTarget = document.createElement('div')
    document.body.appendChild(mountTarget)
  
    function callback() {
      ReactDOM.unmountComponentAtNode(mountTarget)
      mountTarget.parentElement?.removeChild(mountTarget)
    }
  
    ReactDOM.render(
      <Modal
        title='User History'
        visible={true}
        footer={null}
        onCancel={callback}
        width='90vw'
      >
        <div style={{ overflow: 'auto' }}>
          <ClabeAccountList {...props} />
        </div>
      </Modal>,
      mountTarget
    )
  }
  
  
  interface ClabeAccountListProps {
    userId: string
  }
  
  export default ClabeAccountList
  