import styled from "styled-components";
import Scaffold from '../components/Scaffold';
import SessionDemoQr from "./SessionDemoQr";


const SessionDemo = () => {
    return (
        <Scaffold>
            <Container>
                <Section>
                    <h2>QR Method</h2>
                    <SessionDemoQr />
                </Section>
                <Section>
                    <h2>Webhook Method</h2>
                </Section>
            </Container>
        </Scaffold>
    )
}

const Container = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 50px;
`

const Section = styled.div`
    padding: 24px;
    margin: 24px;
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 350px;
    justify-content: center;
    align-items: center;
    border: 1px solid lightgrey;
`

export default SessionDemo