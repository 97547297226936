import { multiFactor } from 'firebase/auth';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AdminAuthLogList from './admins/AdminAuthLogList';
import AdminsScreen from './admins/AdminsScreen';
import SessionDemo from './admins/SessionDemo';
import CompaniesScreen from './companies/CompaniesScreen';
import DashboardScreen from './dashboard/DashboardScreen';
import { getDeploymentInfo, setAuthToken } from './data/EkisClient';
import DevScreen from './developer/DevScreen';
import { getFirebaseAuth } from './firebase-config';
import LoginScreen, { LoginStage } from './login/LoginScreen';
import RechargeScreen from './recharge/RechargeScreen';
import UserApproved from './UserApproved';
import UsersScreen from './users/UsersScreen';
import UserVerificationsScreen from './verification-viewer/UserVerificationsScreen';
import EkisBrokerTest from './developer/EkisBrokerTest';
import SupportScreen from './support/SupportScreen';
import UserDetailScreen from './users/detail/UserDetailScreen';
import LogsScreen from './logs/LogsScreen';

function App() {
  const auth = useMemo(getFirebaseAuth, [])
  const [initizlized, setInitialized] = useState(false)

  useEffect(() => {
    if (['/login', '/user-approved'].includes(window.location.pathname)) {
      setInitialized(true)
      return
    }
    getDeploymentInfo()
      .then(value => {
        (window as any).deploymentInfo = value
      })
      .catch(console.error)

    auth.onAuthStateChanged((user) => {
      if (user) {
        const mfUser = multiFactor(user)
        const phoneFactor = mfUser.enrolledFactors.find(x => x.factorId === "phone")
        if (!phoneFactor) {
          gotoLogin('register_2fa')
          return
        }
        user.getIdToken().then(token => {
          setAuthToken(token)
          setInitialized(true)
        })
      } else {
        gotoLogin()
        return
      }
    });
  }, [])

  function gotoLogin(initialStage?: LoginStage) {
    const query = new URLSearchParams()
    let url = '/login'
    if (window.location.pathname !== '/') {
      query.append('redirect', window.location.href)
    }
    if (initialStage) {
      query.append('stage', initialStage)
    }
    url += `?${query.toString()}`
    console.log(url)
    window.location.href = url
  }

  if (!initizlized) {
    return <div />
  }

  return (
    <>
      <Helmet>
        <link
          rel="icon"
          type="image/x-icon"
          // href={isProduction() ? "/favicon.ico" : "/favicon-bw.ico"}
          href="/favicon.ico"
        />
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route path='/login' element={<LoginScreen />} />
          <Route path='/' element={<DashboardScreen />} />
          <Route path='/users' element={<UsersScreen />} />
          <Route path='/users/:userId' element={<UserDetailScreen />} />
          <Route path='/admins/dev' element={<DevScreen />} />
          <Route path='/admins/ekis-broker' element={<EkisBrokerTest />} />
          <Route path='/admins/logins' element={<AdminAuthLogList />} />
          <Route path='/admins/session-demo' element={<SessionDemo />} />
          <Route path='/admins' element={<AdminsScreen />} />
          <Route path='/verifications' element={<UserVerificationsScreen />} />
          <Route path='/user-approved' element={<UserApproved />} />
          <Route path='/companies' element={<CompaniesScreen />} />
          <Route path='/recharges' element={<RechargeScreen />} />
          <Route path='/support' element={<SupportScreen />} />
          <Route path='/logs' element={<LogsScreen />} />
          {/* <Route path='/transactions' element={<TransactionsScreen />} /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
