export interface ICreateAuthLog {
  action: AuthAction
}

export default interface IAuthLog extends ICreateAuthLog {
  id: string
  timestamp: Date
  adminId: string
  adminName: string
}

export enum AuthAction {
  LOGIN = 'login',
  LOGOUT = 'logout'
}
