import { Button, Form, Input, message, Modal } from 'antd'
import { CSSProperties, useState } from 'react'
import ReactDOM from 'react-dom'
import { createAdmin } from '../data/EkisClient'
import IAdmin from '../models/IAdmin'

export default function AdminForm(props: IAdminFormProps) {
  const [loading, setLoading] = useState(false)

  async function onSubmit(values: any) {
    if (!values.email) return
    setLoading(true)
    try {
      const admin = await createAdmin({
        id: '',
        email: values.email,
        registered: false,
        approved: false
      })
      message.success('Invitation sent')
      props.onResult(admin)
    } catch (e) {
      message.error(`Unable to create user: ${(e as any).message}`)
      props.onResult(undefined)
    }
    setLoading(false)
  }

  return (
    <Form layout='vertical' onFinish={onSubmit}>
      <Form.Item
        label="Email"
        name="email"
        help='An invitation email will be sent to the user'
      >
        <Input />
      </Form.Item>
      <div style={styles.rightAlign}>
        <Form.Item style={{ marginTop: 10 }}>
          <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
            Invite
          </Button>
        </Form.Item>
      </div>
    </Form>
  )
}

export function showAdminForm() {
  return new Promise((resolve, reject) => {
    const mountTarget = document.createElement('div')
    document.body.appendChild(mountTarget)

    function callback(params: IAdmin | undefined) {
      resolve(params)
      ReactDOM.unmountComponentAtNode(mountTarget)
      mountTarget.parentElement?.removeChild(mountTarget)
    }

    ReactDOM.render(
      <Modal
        title='Admin Form'
        visible={true}
        footer={null}
        onCancel={() => callback(undefined)}
      >
        <AdminForm onResult={callback} />
      </Modal>,
      mountTarget
    )
  })
}

interface IAdminFormProps {
  onResult: (admin: IAdmin | undefined) => void
}

const styles: { [key: string]: CSSProperties } = {
  rightAlign: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center'
  }
}
