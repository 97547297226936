import { Button, Card, Form, Input, message, Row } from 'antd'
import { useState } from 'react'
import Scaffold from '../components/Scaffold'
import { ekisBroker } from '../data/EkisClient'


const EkisBrokerTest = () => {
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState("")
  const [form] = Form.useForm();

  async function handleSubmit(values: any) {
    const { accountId, amount } = values
    try {
      setLoading(true)
      const result = await ekisBroker(accountId, amount)
      setResult(JSON.stringify(result, null, 4))
    } catch (e) {
      message.error((e as any).msg || 'Broker Order failed')
      console.error('Request failed');
      setResult(JSON.stringify(e as any, null, 4))
    }
    setLoading(false)
  }

  return (
    <Scaffold>
      <Card style={{ padding: 20, margin: '40px auto', width: 500 }}>
        <h2>Ekis Broker</h2>
        <Form
          form={form}
          layout='vertical'
          onFinish={handleSubmit}
        >

          <Form.Item
            name='accountId'
            label='Account ID'
            required
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='amount'
            label='Amount'
            required
          >
            <Input type='number' />
          </Form.Item>
          <Row align='bottom' justify='end'>
            <Form.Item>
              <Button loading={loading} type="primary" htmlType="submit">
                Execute Order
              </Button>
            </Form.Item>
          </Row>
          <div>
            <div>Result:</div>
            <div>{result}</div>
          </div>
        </Form>
      </Card>
    </Scaffold >
  )
}

export default EkisBrokerTest