import { Button, Form, Input, message, Modal } from 'antd'
import { CSSProperties, useState } from 'react'
import ReactDOM from 'react-dom'
import { createCompany } from '../data/EkisClient'
import ICompany from '../models/ICompany'

export default function CompanyForm(props: ICompanyFormProps) {
  const [loading, setLoading] = useState(false)

  async function onSubmit(values: any) {
    setLoading(true)
    try {
      const company = await createCompany({
        id: '',
        rfc: values.rfc,
        name: values.name || '',
        email: values.email || '',
        phone: values.phone || '',
      })
      message.success('Created')
      props.onResult(company)
    } catch (e) {
      message.error(`Unable to create company: ${(e as any).msg}`)
      props.onResult(undefined)
    }
    setLoading(false)
  }

  return (
    <Form layout='vertical' onFinish={onSubmit}>
      <Form.Item
        label="Name"
        name="name"
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Phone"
        name="phone"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="RFC"
        name="rfc"
        required
      >
        <Input />
      </Form.Item>
      <div style={styles.rightAlign}>
        <Form.Item style={{ marginTop: 10 }}>
          <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
            Submit
          </Button>
        </Form.Item>
      </div>
    </Form>
  )
}

export function showCompanyForm() {
  return new Promise((resolve, reject) => {
    const mountTarget = document.createElement('div')
    document.body.appendChild(mountTarget)

    function callback(params: ICompany | undefined) {
      resolve(params)
      ReactDOM.unmountComponentAtNode(mountTarget)
      mountTarget.parentElement?.removeChild(mountTarget)
    }

    ReactDOM.render(
      <Modal
        title='Company Form'
        visible={true}
        footer={null}
        onCancel={() => callback(undefined)}
      >
        <CompanyForm onResult={callback} />
      </Modal>,
      mountTarget
    )
  })
}

interface ICompanyFormProps {
  onResult: (company: ICompany | undefined) => void
}

const styles: { [key: string]: CSSProperties } = {
  rightAlign: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center'
  }
}
