import { Avatar, Button, Card, Divider, InputNumber, message, Modal, Typography } from 'antd'
import { useState } from 'react'
import ReactDOM from 'react-dom'
import { topup } from '../data/EkisClient'
import ITransaction from '../models/Transaction'
import IUser from '../models/User'

export default function Topup(props: TopupDialogProps) {
  const [loading, setLoading] = useState(false)
  const [amount, setAmount] = useState(props.amount || 0)

  const sendAmount = async () => {
    const amountNum = parseFloat(amount?.toString())
    if (!amountNum || amountNum <= 0) {
      message.warn('Enter valid amount')
      return
    }
    setLoading(true)
    topup(props.user.accountId!!, amountNum.toString())
      .then((tx: any) => {
        setLoading(false)
        message.success('Successfully transfered')
        props.onSuccess(tx)
      })
      .catch((e: any) => {
        console.log(e)
        setLoading(false)
        message.error(`Transfer failed: ${e.msg}`)
      })
  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <Card bordered={false}>
        <Card.Meta
          avatar={<Avatar src={props.user.image} />}
          title={props.user.name}
          description={props.user.id}
        />
      </Card>
      <Divider />
      <Typography.Text style={{ fontSize: 16 }}>
        Enter amount
      </Typography.Text>
      <InputNumber
        size='large'
        value={amount}
        onChange={amount => amount != null ? setAmount(amount) : null}
        type='number'
        style={{ width: 200 }}
      />
      <Button
        type="primary"
        style={{ marginTop: 40, alignSelf: 'end' }}
        loading={loading}
        onClick={sendAmount}
      >
        Submit
      </Button>
    </div>
  )
}

export function showTopupDialog(props: ShowTopupDialogProps) {
  return new Promise((resolve, reject) => {
    const mountTarget = document.createElement('div')
    document.body.appendChild(mountTarget)

    const callback = (tx?: ITransaction) => {
      resolve(tx)
      if (!tx) {
        ReactDOM.unmountComponentAtNode(mountTarget)
        mountTarget.parentElement?.removeChild(mountTarget) 
      }
    }

    ReactDOM.render(
      <Modal
        title={`Topup`}
        visible={true}
        footer={null}
        onCancel={() => callback(undefined)}>
        <Topup
          {...props}
          onCancel={() => callback(undefined)}
          onSuccess={callback}
        />
      </Modal>,
      mountTarget
    )
  })
}
export interface TopupDialogProps {
  user: IUser,
  amount?: number,
  onSuccess: (tx: ITransaction) => void
  onCancel: () => void
}

export interface ShowTopupDialogProps {
  user: IUser,
  amount?: number
}