import { Button, Form } from 'antd'
import { CSSProperties, useState } from 'react'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

function PhoneNumberForm(props: IPhoneNumberFormProps) {
  const [value, setValue] = useState<any>('')

  function onSubmit() {
    props.onSubmit(value)
  }

  return (
    <Form layout='vertical' onFinish={onSubmit}>
      <Form.Item
        label="Phone Number"
        name="phoneNumber"
        help='Please enter your phone number to enable two factor authentication'
      >
        <PhoneInput
          value={value}
          onChange={e => setValue(e)}
        />
      </Form.Item>
      <div style={styles.rightAlign}>
        <Form.Item style={{ marginTop: 10 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </div>
    </Form>
  )
}

interface IPhoneNumberFormProps {
  onSubmit: (phoneNumber: string) => void
}

const styles: { [key: string]: CSSProperties } = {
  rightAlign: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center'
  }
}

export default PhoneNumberForm
