import { Button, DatePicker, Form, Input, message, Modal, Radio, Row, Select } from 'antd'
import _ from 'lodash'
import moment from 'moment'
import { useState } from 'react'
import ReactDOM from 'react-dom'
import { updateUser } from '../data/EkisClient'
import { VerificationName, VerificationSource, VerificationStatus } from '../models/IVerification'
import IUser, { AccountType, UserRole, UserStatus } from '../models/User'

export default function UserForm(props: IUserFormProps) {
  const [user, setUser] = useState(props.user)
  const [verification] = useState(buildVerificationMap(props.user))
  const [loading, setLoading] = useState(false)

  async function handleSubmit(inputs: any) {
    const values: any = {}
    _.keys(inputs).forEach(key => {
      _.set(values, key, inputs[key])
    })
    console.log(values)

    try {
      setLoading(true)
      const { personalId, proofOfResidency, ...formData } = values

      const updated: IUser = {
        ..._.cloneDeep(user),
        ...formData,
        role: user.role as any,
        accountType: user.accountType as any
      }
      updateIfChanged(user, updated, VerificationName.PERSONAL_ID, personalId)
      updateIfChanged(user, updated, VerificationName.PROOF_OF_RESIDENCY, proofOfResidency)

      await updateUser(updated)
      message.success('Updated!')
    } catch (e) {
      message.error('Operation failed')
      console.error(e);
    }
    setLoading(false)
  }

  function updateIfChanged(
    user: IUser,
    updated: IUser,
    name: VerificationName,
    value: VerificationStatus
  ) {
    if (_.find(user.verifications, ['name', name])?.status !== value) {
      const verification = _.find(updated.verifications, ['name', name])
      if (verification) {
        verification.status = value
        verification.source = VerificationSource.MANUAL
      }
    }
  }

  function buildVerificationMap(user: IUser): VerificationFlatMap {
    const verificationMap: Partial<VerificationFlatMap> = {}
    user.verifications.forEach(x => {
      if (x.name === VerificationName.PERSONAL_ID) {
        verificationMap.personalId = x.status
        verificationMap.personalIdSource = x.source
      } else if (x.name === VerificationName.PROOF_OF_RESIDENCY) {
        verificationMap.proofOfResidency = x.status
        verificationMap.proofOfResidencySource = x.source
      }
    })
    return verificationMap as VerificationFlatMap
  }

  return (
    <div>
      <Form
        layout='vertical'
        onFinish={handleSubmit}
      >
        {user.role === UserRole.USER ? (
          <Form.Item
            name='accountType'
            label='Account Type'
            initialValue={user.accountType}
          >
            <Radio.Group
              buttonStyle="solid"
              style={{ marginLeft: 10 }}
              onChange={e => setUser(user => ({ ...user, accountType: e.target.value }))}

            >
              <Radio.Button value={AccountType.CORPORATE}>CORPORATE</Radio.Button>
              <Radio.Button value={AccountType.OWNER}>OWNER</Radio.Button>
              <Radio.Button value={AccountType.PERSONAL}>PERSONAL</Radio.Button>
            </Radio.Group>
          </Form.Item>
        ) : null}
        <Form.Item
          name='name'
          label='Name'
          initialValue={user.name}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='email'
          label='Email'
          initialValue={user.email}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='phoneNumber'
          label='Phone Number'
          initialValue={user.phoneNumber}
        >
          <Input />
        </Form.Item>
        {user.accountType === AccountType.OWNER ? (
          <Form.Item
          name='store.name'
          label='Store Name'
          initialValue={user.store?.name}
        >
          <Input />
        </Form.Item>
        ) : undefined}
        {user.accountType === AccountType.CORPORATE ? (
          <Form.Item
            name='company.name'
            label='Company Name'
            initialValue={user.company?.name}
          >
            <Input />
          </Form.Item>
        ) : null}
        <Form.Item
          name='image'
          label='Image URL'
          initialValue={user.image}
        >
          <Input />
        </Form.Item>
        {user.role === UserRole.USER ? (
          <Row justify='space-between' style={{ marginBottom: 20 }}>
            <Form.Item
              name='dob'
              label='Date of Birth'
              initialValue={user.dob ? moment(user.dob) : null}
            >
              <DatePicker format='YYYY/MM/DD' />
            </Form.Item>
            <Form.Item
              name='status'
              label='Status'
              initialValue={user.status}
            >
              <Select showArrow style={{ minWidth: 160 }}>
                <Select.Option value={UserStatus.ACTIVE}>{UserStatus.ACTIVE}</Select.Option>
                <Select.Option value={UserStatus.INCOMPLETE}>{UserStatus.INCOMPLETE}</Select.Option>
                <Select.Option value={UserStatus.DISABLED}>{UserStatus.DISABLED}</Select.Option>
              </Select>
            </Form.Item>
          </Row>
        ) : null}
        <Row justify='space-between' style={{ marginBottom: 20 }}>
          <Form.Item
            name='personalId'
            label='Personal ID'
            initialValue={verification.personalId}
            help={verification.personalIdSource ? `status set by ${verification.personalIdSource}` : ''}
          >
            <Select showArrow style={{ minWidth: 160 }}>
              <Select.Option value={VerificationStatus.UNINITIALIZED}>{VerificationStatus.UNINITIALIZED}</Select.Option>
              <Select.Option value={VerificationStatus.PROCESSING}>{VerificationStatus.PROCESSING}</Select.Option>
              <Select.Option value={VerificationStatus.VERIFIED}>{VerificationStatus.VERIFIED}</Select.Option>
              <Select.Option value={VerificationStatus.REVIEW_NEEDED}>{VerificationStatus.REVIEW_NEEDED}</Select.Option>
              <Select.Option value={VerificationStatus.REJECTED}>{VerificationStatus.REJECTED}</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name='proofOfResidency'
            label='Proof Of Residency'
            initialValue={verification.proofOfResidency}
            help={verification.proofOfResidencySource ? `status set by ${verification.proofOfResidencySource}` : ''}
          >
            <Select showArrow style={{ minWidth: 160 }}>
              <Select.Option value={VerificationStatus.UNINITIALIZED}>{VerificationStatus.UNINITIALIZED}</Select.Option>
              <Select.Option value={VerificationStatus.PROCESSING}>{VerificationStatus.PROCESSING}</Select.Option>
              <Select.Option value={VerificationStatus.VERIFIED}>{VerificationStatus.VERIFIED}</Select.Option>
              <Select.Option value={VerificationStatus.REVIEW_NEEDED}>{VerificationStatus.REVIEW_NEEDED}</Select.Option>
              <Select.Option value={VerificationStatus.REJECTED}>{VerificationStatus.REJECTED}</Select.Option>
            </Select>
          </Form.Item>
          {/* {user.status === VerificationStatus.VERIFIED ? (
            <Form.Item
              name='verificationSource'
              label='Verified By'
              initialValue={user.verificationSource}
            >
              <Input disabled={true} value={user.verificationSource} />
            </Form.Item>
          ) : null} */}
        </Row>
        <Row align='bottom' justify='end'>
          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div >
  )
}

export function showUserFormDialog(props: IUserFormProps) {
  const mountTarget = document.createElement('div')
  document.body.appendChild(mountTarget)

  function callback(params: any) {
    ReactDOM.unmountComponentAtNode(mountTarget)
    mountTarget.parentElement?.removeChild(mountTarget)
  }

  ReactDOM.render(
    <Modal
      title='Edit User'
      visible={true}
      footer={null}
      onCancel={() => callback(undefined)}>
      <UserForm {...props} />
    </Modal>,
    mountTarget
  )
}

interface VerificationFlatMap {
  personalId: VerificationStatus
  proofOfResidency: VerificationStatus
  personalIdSource: VerificationSource
  proofOfResidencySource: VerificationSource
}

export interface IUserFormProps {
  user: IUser
}