import { ReloadOutlined } from '@ant-design/icons'
import { Button, Divider, List, Skeleton, message } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { getVerifications } from '../../data/EkisClient'
import IMetamapPayload from '../../models/IMetamapPayload'
import IUserVerifications from '../../verification-viewer/IUserVerifications'
import { FlowItem, UserVerificationItem } from '../../verification-viewer/UserVerificationsScreen'
import { buildUserVerifications } from '../../verification-viewer/verifications-helper'

const UserVerificationsComponent = (props: UserVerificationsComponentProps) => {
    const [loading, setLoading] = useState(false)
    const [payloads, setPayloads] = useState<IMetamapPayload[]>()
    const userVerification: IUserVerifications = useMemo(() => buildUserVerifications(payloads || [])[0], [payloads])

    const fetchVerifications = async (userId: string) => {
        if (loading) return
        try {
            setLoading(true)
            const reuslt = await getVerifications(userId)
            setPayloads(reuslt)
        } catch (e) {
            message.error(e)
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchVerifications(props.userId)
    }, [])

    return (
        <div>
            <Row>
                <h3>Verifications</h3>
                <Button shape='circle' icon={<ReloadOutlined />} onClick={() => fetchVerifications(props.userId)} />
            </Row>
            <Divider />
            {(!userVerification || loading) ? <Skeleton /> : <>
                <UserVerificationItem {...userVerification} />
                <List
                    size='small'
                    dataSource={userVerification.flows}
                    renderItem={flow => <FlowItem {...flow} />}
                />
            </>}
        </div>
    )
}

const Row = styled.div`
    display: flex;
    justify-content: space-between;
`

interface UserVerificationsComponentProps {
    userId: string
}

export default UserVerificationsComponent

