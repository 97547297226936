export default interface IMetamapPayload {
  id: string
  flowId: string
  eventName: MetamapEventType
  timestamp: string
  metadata: IMetaData
  identityStatus?: IdentityStatus
  status?: IdentityStatus
  step?: IMetamapStep
  resource: any
  isManual?: boolean
}

export interface IMetaData {
  pubkey: string
  userId: string
  processId: string
}

export type MetamapEventType = 'verification_started' |
  'step_completed' |
  'verification_inputs_completed' |
  'verification_expired' |
  'verification_completed' |
  'verification_updated'

export type IdentityStatus = 'verified' | 'reviewNeeded' | 'rejected'

export type IMetamapStepId = 'template-matching' |
  'liveness' |
  'facematch' |
  'document-reading' |
  'age-check' |
  'watchlists' |
  'email-ownership-validation' |
  'alteration-detection'

export interface IMetamapStep {
  id: IMetamapStepId | string
  documentType?: string
  error?: {
    message: string
    type: string
    code: string
  }
  data?: any
}

export enum MetamapFlow {
  PERSONAL_ID = '6194080d8f867f001d14985a',
  PROOF_OF_RESIDENCY = '61b9f49caaa340001bb44a45',
  EMAIL = '61964e71382ca2001b11814d'
}
