import { Button, Card, message, Spin, Typography } from 'antd';
import {
  getMultiFactorResolver,
  GoogleAuthProvider,
  multiFactor,
  MultiFactorResolver,
  MultiFactorUser,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  signInWithPopup,
  User,
  browserLocalPersistence
} from 'firebase/auth';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { getFirebaseAuth } from '../firebase-config';
import GoogleLogo from '../images/google.svg';
import OtpForm from './OtpForm';
import PhoneNumberForm from './PhoneNumberForm';
import Wave from '../images/wave.svg';
import EkisLogo from '../images/ekis-logo-text.svg';
import { createAdminAuthLog, getAdminByEmail, setAuthToken } from '../data/EkisClient';
import { AuthAction } from '../models/IAuthLog';
import { LANDING_PAGE } from '../constants';

const { Text } = Typography
export type LoginStage = 'init' | 'register_2fa' | 'register_otp' | 'login_otp' | 'awaiting_verification'


function LoginScreen() {
  const auth = useRef(getFirebaseAuth())
  const recaptchaContainer = useRef<HTMLDivElement>(null)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [loading, setLoading] = useState(false)
  const [stage, setStage] = useState<LoginStage>('init')
  const userRef = useRef<User | null>(null)
  const verificationId = useRef<string | undefined>()
  const userMultiFactor = useRef<MultiFactorUser | undefined>()
  const resolver = useRef<MultiFactorResolver | undefined>()
  const loginError = useRef()

  useEffect(() => {
    auth.current.onAuthStateChanged((user) => {
      userRef.current = user
      if (user) {
        const query = new URLSearchParams(window.location.search)
        const stage = query.get('stage') as LoginStage | undefined
        if (stage === 'register_2fa') {
          setStage('register_2fa')
        }
      }
    });
  }, [])

  async function login() {
    try {
      const provider = new GoogleAuthProvider()
      setLoading(true)
      await auth.current.setPersistence(browserLocalPersistence)
      const result = await signInWithPopup(auth.current, provider)

      const credential = GoogleAuthProvider.credentialFromResult(result);
      console.log('credential.accessToken', credential?.accessToken)
      console.log('user', result.user)
      const invitedUser = await getAdminByEmail(result.user.email!)
      if (invitedUser && invitedUser.approved) {
        setStage('register_2fa')
      } else {
        // first time login. register for 2FA
        setStage('awaiting_verification')
      }
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      if (error.code === 'auth/multi-factor-auth-required') {
        loginError.current = error
        sendLoginOtp(error)
      } else if (error.code === 'auth/user-disabled') {
        setStage('awaiting_verification')
      } else if (error.code === 'auth/popup-closed-by-user') {
        console.log('cancelled')
      } else {
        console.error(error)
        const errorCode = error.code;
        const errorMessage = error.message;
        message.error(`Login failed: [${errorCode}] - ${errorMessage}`)
      }
    }
  }

  async function enable2FA(phoneNumber: string) {
    setLoading(true)
    try {
      userMultiFactor.current = multiFactor(userRef.current!)
      const multiFactorSession = await userMultiFactor.current.getSession()
      console.log('multiFactorSession', multiFactorSession)
      const phoneAuthProvider = new PhoneAuthProvider(auth.current);

      const container = createChildDiv(recaptchaContainer.current!)
      const recaptchaVerifier = new RecaptchaVerifier(
        container,
        { size: 'invisible' },
        auth.current
      )

      // Send SMS verification code.
      verificationId.current = await phoneAuthProvider.verifyPhoneNumber(
        {
          phoneNumber: phoneNumber,
          session: multiFactorSession
        },
        recaptchaVerifier
      )
      setPhoneNumber(phoneNumber)
      setStage('register_otp')
    } catch (e) {
      console.error(e)
    }
    setLoading(false)
  }

  async function sendLoginOtp(error: any) {
    setLoading(true)
    resolver.current = getMultiFactorResolver(auth.current, error);
    const phoneFactor = resolver.current.hints.find(x => x.factorId === "phone")
    if (phoneFactor) {
      setPhoneNumber((phoneFactor as any).phoneNumber)
      const phoneInfoOptions = {
        multiFactorHint: resolver.current.hints[0],
        session: resolver.current.session
      };
      const phoneAuthProvider = new PhoneAuthProvider(auth.current);
      // Send SMS verification code
      const container = createChildDiv(recaptchaContainer.current!)
      const recaptchaVerifier = new RecaptchaVerifier(
        container,
        { size: 'invisible' },
        auth.current
      )
      verificationId.current = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        recaptchaVerifier
      )
      recaptchaVerifier.clear()
      setLoading(false)
      setStage('login_otp')
    } else {
      setStage('register_2fa')
    }
  }

  async function handleLoginOtp(otp: string) {
    setLoading(true)
    try {
      const cred = PhoneAuthProvider.credential(verificationId.current!, otp);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      // Complete sign-in.
      const userCeredential = await resolver.current!.resolveSignIn(multiFactorAssertion)
      const token = await userCeredential.user.getIdToken()
      await onLoginSuccess(token)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      const error = e as any
      if (error.code === 'auth/invalid-verification-code') {
        message.error('Invalid OTP')
      } else {
        message.error(error.code || error.message || 'Unknown error')
      }
      console.error('login failed', e)
    }
  }

  async function handleRegisterOtp(otp: string) {
    setLoading(true)
    // Ask user for the verification code.
    const cred = PhoneAuthProvider.credential(verificationId.current!, otp);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
    // Complete enrollment.
    await userMultiFactor.current!.enroll(multiFactorAssertion, 'My personal phone number');
    setLoading(false)
    // registered for 2FA
    window.location.href = LANDING_PAGE
  }

  function renderStage(stage: LoginStage) {
    switch (stage) {
      case 'init':
        return (
          <Button
            icon={<img style={styles.google} src={GoogleLogo} alt='' />}
            onClick={login}
            shape="round"
            style={{ borderColor: 'var(--primary-color)' }}
          >
            Sign in with Google
          </Button>
        )
      case 'register_2fa':
        return <PhoneNumberForm onSubmit={enable2FA} />
      case 'register_otp':
        return <OtpForm
          phoneNumber={phoneNumber}
          onSubmit={handleRegisterOtp}
          onResendOtp={() => enable2FA(phoneNumber)}
        />
      case 'login_otp':
        return <OtpForm
          phoneNumber={phoneNumber}
          onSubmit={handleLoginOtp}
          onResendOtp={() => sendLoginOtp(loginError.current)}
        />
      case 'awaiting_verification':
        return <div>Your request is received. Our team will review it and grant you access. Please keep an eye on your mailbox.</div>
    }
  }

  async function onLoginSuccess(token: string) {
    try {
      setAuthToken(token)
      await createAdminAuthLog({ action: AuthAction.LOGIN })
    } catch (e) {
      console.error(e)
    }

    const queryParams = new URLSearchParams(window.location.search)
    const redirect = queryParams.get('redirect')
    window.location.href = redirect || LANDING_PAGE
  }

  function createChildDiv(parent: HTMLElement): HTMLDivElement {
    const div = document.createElement('div')
    parent.appendChild(div)
    return div
  }

  return (
    <div style={styles.center}>
      <div ref={recaptchaContainer} />
      <Text style={styles.title}>Sign in</Text>
      <Card bodyStyle={styles.cardBody} style={styles.card}>
        <img style={{ width: 150 }} src={EkisLogo} alt='' />
        <Text style={styles.text}>Bienvenido al UMS</Text>
        {renderStage(stage)}
        {loading ? (
          <div style={styles.loader}>
            <Spin />
          </div>
        ) : null}
      </Card>
    </div>
  )
}

const styles: { [key: string]: CSSProperties } = {
  google: { width: 20, height: 20, marginRight: 10 },
  center: {
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    background: `url(${Wave})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  card: {
    borderRadius: '10px',
    overflow: 'hidden',
    background: '#F2F2F2',
    width: 440,
    minHeight: 250,
    // aspectRatio: '16 / 9'
  },
  cardBody: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    padding: 30,
  },
  loader: {
    position: 'absolute',
    background: 'rgba(0,0,0,0.2)',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  text: {
    fontSize: 24,
    fontWeight: 'normal',
    margin: '20px 0'
  },
  title: {
    fontSize: 28,
    color: 'white',
    marginBottom: 8
  }
}

export default LoginScreen
