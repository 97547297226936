import { Button, Col, Divider, message, PageHeader, Row, Statistic } from 'antd'
import { useEffect, useState } from 'react'
import Scaffold from '../components/Scaffold'
import { downlaodBalanceReport, getDashboardData } from '../data/EkisClient'
import IDashboardData from '../models/IDashboardData'

export default function DashboardScreen() {
  const [loading, setLoading] = useState(false)
  const [downloadingReport, setDownloadingReport] = useState(false)
  const [data, setData] = useState<IDashboardData>()
  const [deploymentInfo, setDeploymentInfo] = useState<string>()

  useEffect(() => {
    setDeploymentInfo((window as any).deploymentInfo)
  })


  const fetchDashboardData = (showLoader: boolean) => {
    showLoader && setLoading(true)
    getDashboardData()
      .then(res => {
        setData(res)
        showLoader && setLoading(false)
      })
      .catch(e => {
        showLoader && setLoading(false)
      })
  }

  useEffect(() => {
    fetchDashboardData(true)
  }, [])

  const renderDownloadReportButton = () => (
    <Button
      loading={downloadingReport}
      onClick={async e => {
        setDownloadingReport(true)
        try {
          const blob = await downlaodBalanceReport()
          const downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.download = 'balances.xlsx'
          downloadLink.click();
        } catch (e) {
          console.error(e)
          message.error('Unable to download report')
        }
        setDownloadingReport(false)
      }}
    >
      Download Balance Report
    </Button>
  )

  return (
    <Scaffold>
      <PageHeader
        ghost={false}
        extra={renderDownloadReportButton()}
        title="ekis Dashboard"
      />
      <Divider />
      <div style={{ width: 900, margin: '0 auto', paddingBottom: 40 }}>
        <Row gutter={24} style={{ marginBottom: 40 }}>
          <Col span={8}>
            <Statistic title="Active Users" value={data?.userCount} loading={loading} />
          </Col>
          <Col span={8}>
            <Statistic title="Active Merchants" value={data?.merchantCount} loading={loading} />
          </Col>
          <Col span={8}>
            <Statistic title="Total" value={data?.totalUserCount} loading={loading} />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Statistic title="ekis in Circulation" value={data?.circulationBalance} precision={2} loading={loading} />
          </Col>
          <Col span={8}>
            <Statistic title="ekis in Treasury" value={data?.treasuryBalance} precision={2} loading={loading} />
          </Col>
        </Row>
      </div>
      {/* <TransactionsChart
        transactions={transactions}
      /> */}
      <div style={{ position: 'absolute', right: '10px', bottom: '10px', whiteSpace: 'pre-line' }}>
        {deploymentInfo}
      </div>
    </Scaffold >
  )
}
