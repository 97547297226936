import IUser from "./User"

export default interface ITransaction {
  sender: Partial<IUser>
  senderAccountId: string
  receiver: Partial<IUser>
  receiverAccountId: string
  type: TxType
  amount: number
  timestamp: number
  txhash: string
  eventId: number
  feeMemo: string
  totalFeeAmount: number
  rawData?: any
}


export enum TxType {
  INCOMING = 'incoming',
  OUTGOING = 'outgoing'
}

