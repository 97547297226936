import { CSSProperties } from "react"
import IStat from "../models/IStat"
import Icon from "./Icon"

export default function StatCard(props: StatCardProps) {
  return (
    <div style={styles.container}>
      <div style={styles.row}>
        <Icon name={props.stat.icon} width={40} height={40} />
        <div style={styles.title}>{props.stat.name}</div>
      </div>
      <div style={styles.value}>{props.stat.value}</div>
    </div>
  )
}

interface StatCardProps {
  stat: IStat
}

const styles: { [key: string]: CSSProperties } = {
  container: {
    width: 240,
    height: 160,
    borderRadius: 10,
    boxShadow: '0px 0px 6px #00000029',
    background: 'white',
    padding: 22,
    margin: '8px'
  },
  row: {
    display: 'flex',
    width: '100%',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space'
  },
  title: {
    fontSize: 20,
    marginLeft: 10
    // whiteSpace: 'nowrap'
  },
  value: {
    fontSize: 36,
    display: 'flex',
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  }
}