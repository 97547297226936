import { AuditOutlined, CodeOutlined, CustomerServiceOutlined, LeftOutlined, LoginOutlined, ProfileOutlined, RightOutlined, WalletOutlined } from '@ant-design/icons';
import { Button, Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Icon from './components/Icon';
import { getFirebaseAuth } from './firebase-config';
import EkisLogo from './images/ekis_logo.svg';
import { AccountType } from './models/User';

export default function NavMenu() {
  const auth = useMemo(getFirebaseAuth, [])
  const [collapsed, setCollapsed] = useState(false)
  const keys = [window.location.pathname.split('/').pop()!]

  function handleLogout() {
    auth.signOut()
  }

  return (
    <div>
      <Link to='/'>
        <img src={
          collapsed ? EkisLogo : '/images/ekis_logo_full.svg'
        } style={styles.logo as any} alt='logo' />
      </Link>
      <Menu
        selectedKeys={keys}
        mode="inline"
        inlineCollapsed={collapsed}
      >
        <SubMenu
          key="users"
          title={
            <span>
              <Icon name='user' />
              <span>Usuarios</span>
            </span>
          }
        >
          <Menu.Item key='allusers' icon={<Icon name='user' />}>
            <Link to='/users'>Todos</Link>
          </Menu.Item>
          <Menu.Item key='companyusers' icon={
            <Icon name='company' />
          }>
            <Link to='/users'>Empresas aliadas</Link>
          </Menu.Item>
          <Menu.Item key='personal' icon={<Icon name='user_phone' />}>
            <Link to={`/users?accountType=${AccountType.PERSONAL}`}>Wallet Personales</Link>
          </Menu.Item>
          <Menu.Item key='corporate' icon={<Icon name='company_phone' />}>
            <Link to={`/users?accountType=${AccountType.CORPORATE}`}>Wallet empresariales</Link>
          </Menu.Item>
          <Menu.Item key='owner' icon={<Icon name='shop_phone' />}>
            <Link to={`/users?accountType=${AccountType.OWNER}`}>Wallet Negocios</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="adminshead"
          title={
            <span>
              <Icon name='user' />
              <span>Admins</span>
            </span>
          }
        >
          <Menu.Item key='admins' icon={<Icon name='user' />}>
            <Link to='/admins'>Admins</Link>
          </Menu.Item>
          <Menu.Item key='loginslogs' icon={<AuditOutlined style={styles.icon} />}>
            <Link to={`/admins/logins`}>Logins</Link>
          </Menu.Item>
          <Menu.Item key='dev' icon={<CodeOutlined style={styles.icon} />}>
            <Link to='/admins/dev'>Dev Tools</Link>
          </Menu.Item>
          <Menu.Item key='session-demo' icon={<LoginOutlined style={styles.icon} />}>
            <Link to='/admins/session-demo'>Session Demo</Link>
          </Menu.Item>
          <Menu.Item key='ekis-broker' icon={<WalletOutlined style={{ color: 'var(--primary-color)' }} />}>
            <Link to='/admins/ekis-broker'>Ekis Broker</Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key='verifications' icon={<Icon name='verifications' />}>
          <Link to='/verifications'>Verifications</Link>
        </Menu.Item>
        <Menu.Item key='recharges' icon={<WalletOutlined style={{ color: 'var(--primary-color)' }} />}>
          <Link to='/recharges'>Recharges</Link>
        </Menu.Item>
        <Menu.Item key='companies' icon={<Icon name='company' />}>
          <Link to='/companies'>Companies</Link>
        </Menu.Item>
        <Menu.Item key='logs' icon={<ProfileOutlined style={{ color: 'var(--primary-color)' }} />}>
          <Link to='/logs'>Logs</Link>
        </Menu.Item>
        <Menu.Item key='support' icon={<CustomerServiceOutlined style={{ color: 'var(--primary-color)' }} />}>
          <Link to='/support'>Support</Link>
        </Menu.Item>
        {/* <Menu.Item key="transactions" icon={<TransactionOutlined />}>
        <Link to='/transactions'>Transactions</Link>
        </Menu.Item> */}
        <Menu.Item key="logout" icon={<Icon name='logout' />} onClick={handleLogout}>
          Logout
        </Menu.Item>
      </Menu>
      <div style={{ display: 'flex', flex: 1 }} />
      <Button type="link" onClick={() => setCollapsed(!collapsed)} style={{ margin: 'auto', width: '100%' }}>
        {React.createElement(collapsed ? RightOutlined : LeftOutlined)}
      </Button>
    </div>
  );
}

const styles = {
  logo: {
    width: '100%',
    height: 35,
    margin: '10px 0',
    objectFit: 'contain',
    // filter: `grayscale(${isProduction() ? 0 : 1})`
  },
  icon: { fontSize: 20, color: 'var(--primary-color)' }
}