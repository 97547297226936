import styled from 'styled-components';

export default function UserItem(props: UserItemProps) {
  return (
    <div className='row v-center'>
      <Avatar src={props.image} style={{ marginRight: 8, objectFit: 'cover' }} />
      <div>{props.name}</div>
    </div>
  )
}

const Avatar = styled.img`
width: 30px;
height: 30px;
margin-right: 8px;
object-fit: cover;
`

interface UserItemProps {
  name: string
  image: string
}