import { Divider, message, PageHeader, Table } from "antd"
import moment from 'moment'
import { useEffect, useState } from 'react'
import Scaffold from "../components/Scaffold"
import { getAdminAuthLogs } from '../data/EkisClient'
import IAuthLog from '../models/IAuthLog'

const AdminAuthLogList = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<IAuthLog[]>([])

  const columns: any[] = [
    {
      title: 'Timestamp',
      dataIndex: 'timestamp',
      render: (date: string) => {
        return <div>{moment(date).format('DD MMM yyyy hh:mm:ss')}</div>
      }
    },
    {
      title: 'Email',
      dataIndex: 'adminName',
    },
    {
      title: 'ID',
      dataIndex: 'adminId'
    },
    {
      title: 'Action',
      dataIndex: 'action'
    },
  ]

  function fetchData() {
    setLoading(true)
    getAdminAuthLogs()
      .then(data => {
        setData(data)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        message.error('Unable to load data')
      })
  }

  useEffect(fetchData, [])

  return (
    <Scaffold>
      <PageHeader title="Admin Logins" >
      </PageHeader>
      <Divider />
      <Table
        loading={loading}
        columns={columns}
        dataSource={data}
        rowKey='id'
        pagination={false}
      />
    </Scaffold>
  )
}

export default AdminAuthLogList
