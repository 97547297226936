import { ArrowRightOutlined } from '@ant-design/icons'
import { Alert, Button, DatePicker, Form, message, Modal, Radio, Row } from 'antd'
import moment from 'moment'
import { useState } from 'react'
import ReactDOM from 'react-dom'
import { exportTransferHistory, ExportTransferHistoryParams } from '../data/EkisClient'
import { TxType } from '../models/Transaction'
import IUser from '../models/User'

export default function UserTxExport(props: UserTxExportFormProps) {
  const [loading, setLoading] = useState(false)

  async function handleOnFinish(values: IUserTxExportForm) {
    setLoading(true)
    await downloadReport(values, props.user)
      .then(res => {
        window.open(res.url, "_blank")
      })
      .catch(e => {
        console.error(e)
        message.error('Unable to fetch report data')
      })
    setLoading(false)
  }

  function downloadReport(input: IUserTxExportForm, user: IUser) {
    const params: ExportTransferHistoryParams = {
      accountId: user.accountId!!
    }
    if (input.dateFrom) {
      params.fromDate = moment(input.dateFrom).utc(false).toISOString()
    }
    if (input.dateTo) {
      params.toDate = moment(input.dateTo).utc(false).toISOString()
    }
    if (input.type !== 'all') {
      params.type = input.type
    }
    return exportTransferHistory(params)
  }

  return (
    <div>
      <Form
        layout='vertical'
        onFinish={handleOnFinish}
      >
        <Alert showIcon message="Ensure popups are enabled for this site" type="warning" />
        <br />
        <Row align='middle' justify='space-between'>
          <Form.Item
            name='dateFrom'
            label='From Date'
          >
            <DatePicker placeholder='Start' />
          </Form.Item>
          <ArrowRightOutlined />
          <Form.Item
            name='dateTo'
            label='To Date'
          >
            <DatePicker placeholder='Latest' />
          </Form.Item>
        </Row>
        <Row align='middle' justify='space-between'>
          <Form.Item
            name="type"
            label="Transaction type"
            initialValue='all'
          >
            <Radio.Group>
              <Radio.Button value="all">All</Radio.Button>
              <Radio.Button value="incoming">Incoming</Radio.Button>
              <Radio.Button value="outgoing">Outgoing</Radio.Button>
            </Radio.Group>
          </Form.Item>
          {/* <Form.Item
            name="format"
            label="Export format"
            initialValue='xls'
          >
            <Radio.Group>
              <Radio.Button value="xls">XLS</Radio.Button>
              <Radio.Button value="csv">CSV</Radio.Button>
            </Radio.Group>
          </Form.Item> */}
        </Row>
        <Row align='bottom' justify='end'>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Download
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  )
}


export function showUserTxExportDialog(props: UserTxExportFormProps) {
  return new Promise((resolve, reject) => {
    const mountTarget = document.createElement('div')
    document.body.appendChild(mountTarget)

    function callback(params: any) {
      ReactDOM.unmountComponentAtNode(mountTarget)
      mountTarget.parentElement?.removeChild(mountTarget)
      resolve('')
    }

    ReactDOM.render(
      <Modal
        title='Export transaction data'
        visible={true}
        footer={null}
        onCancel={() => callback(undefined)}>
        <UserTxExport {...props} />
      </Modal>,
      mountTarget
    )
  })
}

interface UserTxExportFormProps {
  user: IUser
}

interface IUserTxExportForm {
  dateFrom: Date
  dateTo: Date
  type: TxType | 'all'
  format: string
}
