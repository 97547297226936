import _ from "lodash"
import { EkisEnv, getEkisEnv } from "../config"
import IAccountInfo from "../models/IAccountInfo"
import IAdmin from "../models/IAdmin"
import { ICreateAuthLog } from "../models/IAuthLog"
import ICompany from "../models/ICompany"
import IDashboardData from "../models/IDashboardData"
import IErpMap from "../models/IErpMap"
import IMetamapPayload from "../models/IMetamapPayload"
import IRechargeRequest from "../models/IRechargeRequest"
import ISendNotificationInput from "../models/ISendNotificationInput"
import ISessionRequest from "../models/ISessionRequest"
import IUserUpdateLog from "../models/IUserUpdateLog"
import ITransaction, { TxType } from "../models/Transaction"
import IUser from "../models/User"
import UserFilters from "../models/UserFilters"
import HttpClient from "./HttpClient"
import IOtpResponse from "../models/IOtpResponse"
import { IPaycashPaymentStatusData } from "../models/IPaycashWebhookPayload"

const host = getApiHost()

function getApiHost() {
  switch (getEkisEnv()) {
    case EkisEnv.DEV:
      return 'https://api-dev.ekis.io/api'
    case EkisEnv.TEST:
      return 'https://us-central1-ekis-test.cloudfunctions.net/ekis'
    case EkisEnv.STAGING:
      return 'https://us-central1-ekis-stag.cloudfunctions.net/ekis'
    case EkisEnv.PILOT:
      return 'https://api.ekis.io/api'
    case EkisEnv.PILOT_LOCAL:
      return 'http://localhost:5001/ekis-pilot/us-central1/ekis'
    case EkisEnv.DEV_LOCAL:
      return 'http://localhost:5001/ekis-dev/us-central1/ekis'
    case EkisEnv.STAGING_LOCAL:
      return 'http://localhost:5001/ekis-stag/us-central1/ekis'
    default:
      return 'https://us-central1-ekis-pilot.cloudfunctions.net/ekis'
  }
}

const http = new HttpClient(host)

export function setAuthToken(token: string) {
  http.headers = {
    ...http.headers,
    'Authorization': token
  }
}

export function getUsers(query?: UserFilters): Promise<IUser[]> {
  let path = `/users?includeDisabled=${query?.includeDisabled === true}`
  if (query?.role) {
    path += `&role=${query.role}`
  }
  if (query?.phoneNumber) {
    path += `&phoneNumber=${encodeURIComponent(query.phoneNumber)}`
  }
  if (query?.email) {
    path += `&email=${encodeURIComponent(query.email)}`
  }
  if (query?.accountId) {
    path += `&accountId=${query.accountId}`
  }
  return http.get(path)
}

export function getUser(key: string, property: string = 'id'): Promise<IUser> {
  let path = `/users/${key}?includeDisabled=true&field=${property}`
  return http.get(path)
}

export async function getTransactions(accountId: string): Promise<ITransaction[]> {
  return http.get(`/account/${accountId}/transactions`)
}

export async function getTransferHistory(accountId: string): Promise<ITransaction[]> {
  return http.get(`/account/${accountId}/transfer-history`)
}

export async function getTreasury(): Promise<IUser> {
  return http.get('/account/treasury')
}

export async function getDashboardData(): Promise<IDashboardData> {
  return http.get('/reports/dashboard')
}

// export async function transferFund(from: string, to: string, amount: number, sessionToken: string): Promise<ITransaction> {
//   const body: Partial<ITransaction | { sessionToken: string }> = { from, to, amount, sessionToken }
//   return http.post('/account/transfer-funds', body)
// }

export async function topup(accountId: string, amount: string) {
  return http.post(`/account/topup`, { accountId, amount })
}

export async function createSessionToken(): Promise<{ token: string }> {
  return http.post('/auth/grpc-session')
}

export async function sendNotificatoin(params: ISendNotificationInput) {
  return http.post('/users/send_notification', params)
}

export async function updateUser(user: IUser) {
  return http.put(`/users/${user.id}`, user)
}

export async function getVerifications(userId?: string): Promise<IMetamapPayload[]> {
  let url = '/verifications'
  if (userId) {
    url += `?userId=${userId}`
  }
  return http.get(url)
}

export async function getAdmins(): Promise<IAdmin[]> {
  return http.get('/ums/admins')
}

export async function createAdmin(admin: IAdmin): Promise<IAdmin> {
  return http.post('/ums/admins', admin)
}

export async function getAdminByEmail(email: string): Promise<IAdmin | undefined> {
  return http.get(`/ums/admins/${email}?key=email`)
}

export async function deleteAdmin(id: string): Promise<IAdmin | undefined> {
  return http.delete(`/ums/admins/${id}`)
}

export function sendAdminInvitation(id: string) {
  return http.post(`/ums/admins/${id}/send-invite`)
}

export function approveAdmin(id: string) {
  return http.post(`/ums/admins/${id}/approve`)
}

export function getUserAudits(userId?: string): Promise<IUserUpdateLog[]> {
  let url = '/user-audits/updates'
  if (userId) {
    url += `/${userId}`
  }
  return http.get(url)
}

export function getClabeAccounts(userId:String) {
  return http.get(`/clabe-accounts?userId=${userId}`)
}

export function getAdminAuthLogs() {
  return http.get('/ums/admins/log')
}

export function createAdminAuthLog(log: ICreateAuthLog) {
  return http.post('/ums/admins/log', log)
}

export function recoverAccount(accountId: string, publicKey: string) {
  return http.post('/account/recover-account', { accountId, publicKey })
}

export function getPublicKey(accountId: string): Promise<string> {
  return http.get(`/users/get-publickey?accountId=${accountId}`)
    .then(data => data.publicKey)
}

export function getPbtxBalances(accountIds: string[]): Promise<IAccountInfo[]> {
  return http.get(`/account/pbtx-balances?accountIds=${accountIds.join(',')}`)
}


export function getPbtxBalance(accountId: string): Promise<IAccountInfo | undefined> {
  return getPbtxBalances([accountId])
    .then(result => result.find(x => x.owner.toString() === accountId))
}

export function createSessionRequest(body: ISessionRequest) {
  return http.post(`/session-requests`, body)
}

export function getSessionRequest(id: string): Promise<ISessionRequest> {
  return http.get(`/session-requests/${id}`)
}

export function getCompanies() {
  return http.get('/companies')
}

export function createCompany(data: Partial<ICompany>): Promise<ICompany> {
  return http.post('/companies', data)
}

export function getErpMap(): Promise<IErpMap> {
  return http.get('/users/erp')
}

export function updateErpMap(erpMap: IErpMap) {
  return http.put('/users/erp', erpMap)
}

export function downlaodBalanceReport() {
  return fetch(host + '/reports/excel/balances', {
    method: 'GET',
  }).then(response => response.blob())
}

export function getRechargeRequests(): Promise<IRechargeRequest[]> {
  return http.get('/recharge/requests')
}

export function ekisBroker(accountId: string, amount: number): Promise<any> {
  return http.post('/recharge/ekis-broker', { accountId, amount })
}

export function completeRecharge(payload: IPaycashPaymentStatusData): Promise<any> {
  return http.post('/recharge/complete-recharge', payload)
}

export function simulateMetamapWebhook(flowId: string, timestamp: string): Promise<any> {
  return http.post('/simulate-metamap', { flowId, timestamp })
}

export function sendOtp(to: string, channel: string): Promise<IOtpResponse> {
  return http.post('/auth/send-otp', { to, channel })
}

export function verifyOtp(to: string, code: string): Promise<IOtpResponse> {
  return http.post('/auth/verify-otp', { to, code })
}

export interface ExportTransferHistoryParams {
  accountId: string,
  fromDate?: Date | string,
  toDate?: Date | string
  type?: TxType
}

export function exportTransferHistory(params: ExportTransferHistoryParams): Promise<{ url: string }> {
  const query = new URLSearchParams()
  const filteredParams: any = { ...params }
  delete filteredParams.accountId
  for (const key of _.keys(params)) {
    const value = filteredParams[key]
    if (value) query.append(key, value)
  }

  return http.get(`/account/${params.accountId}/transfer-history/export?${query.toString()}`)
}

export function getDeploymentInfo(): Promise<string | undefined> {
  return http.get('/deployment-info')
}
