import ReactDOM from 'react-dom'
import { ArrowRightOutlined } from '@ant-design/icons'
import { Avatar, message, Modal, Table } from 'antd'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { getUserAudits } from '../../data/EkisClient'
import IUserUpdateLog from '../../models/IUserUpdateLog'


const UserAuditList = (props: UserAuditListProps) => {
  const [updates, setUpdates] = useState<IUserUpdateLog[]>([])
  const [loading, setLoading] = useState(false)

  const fetchData = () => {
    setLoading(true)
    getUserAudits(props.userId)
      .then(result => {
        setUpdates(result)
        setLoading(false)
      })
      .catch(e => {
        message.error(e.msg || 'Something went wrong')
        setLoading(false)
      })
  }

  useEffect(() => fetchData(), [])

  const data = useMemo(() => {
    const list: UserDiffFlat[] = []
    for (const update of updates) {
      for (const [property, diff] of Object.entries(update.diff)) {
        const [from, to] = diff
        list.push({
          timestamp: moment(update.timestamp).format('DD MMM yyyy hh:mm'),
          property,
          from,
          to,
          updater: update.adminName || 'User'
        })
      }
    }
    return list
  }, [updates])

  const renderDiff = (value: any, item: UserDiffFlat) => {
    if (value === null) return 'null';
    return item.property === 'image'
      ? <Avatar src={value} />
      : value
  }

  const getColorForDiff = (record: UserDiffFlat) => {
    console.log(record)
    const { from, to } = record
    if (from === null) return 'green'
    if (from && !to) return 'red'
    if (!from && to) return 'green'
    if (from && to) return 'darkorange'
  }

  return (
    <Table
      loading={loading}
      size='small'
      pagination={{ hideOnSinglePage: true, pageSize: 100 }}
      dataSource={data}
      columns={[
        {
          dataIndex: 'timestamp',
          width: 100,
          title: 'Updated On',
          sorter: (a: UserDiffFlat, b: UserDiffFlat) => (new Date(a.timestamp)).valueOf() - (new Date(b.timestamp)).valueOf(),
          defaultSortOrder: 'descend' as any,
        }, {
          dataIndex: 'updater',
          width: 150,
          title: 'Modified By'
        }, {
          dataIndex: 'property',
          width: 110,
          title: 'Property'
        }, {
          dataIndex: 'from',
          render: renderDiff, width: 200,
          ellipsis: true,
          title: 'Old Value'
        }, {
          render: (record) => <ArrowRightOutlined style={{ color: getColorForDiff(record) }} />,
          width: 30,
        }, {
          dataIndex: 'to',
          render: renderDiff,
          width: 200,
          ellipsis: true,
          title: 'New Value'
        },
      ]}
    />
  )
}

export function showUserAuditList(props: UserAuditListProps) {
  const mountTarget = document.createElement('div')
  document.body.appendChild(mountTarget)

  function callback() {
    ReactDOM.unmountComponentAtNode(mountTarget)
    mountTarget.parentElement?.removeChild(mountTarget)
  }

  ReactDOM.render(
    <Modal
      title='User History'
      visible={true}
      footer={null}
      onCancel={callback}
      width='90vw'
    >
      <div style={{ overflow: 'auto' }}>
        <UserAuditList {...props} />
      </div>
    </Modal>,
    mountTarget
  )
}

interface UserDiffFlat {
  timestamp: string
  property: string
  from: any
  to: any
  updater: string
}


interface UserAuditListProps {
  userId: string
}

export default UserAuditList
