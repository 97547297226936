import _ from "lodash"
import moment from "moment"
import IFlow from "../models/IFlow"
import IMetamapPayload, { MetamapFlow } from "../models/IMetamapPayload"
import { VerificationStatus } from "../models/IVerification"
import IUserVerifications from "./IUserVerifications"

export function buildUserVerifications(steps: IMetamapPayload[]) {
  const flows = groupToFlows(steps)
  const userVerificatios: { [key: string]: IUserVerifications } = {}

  for (const flow of flows) {
    const userId = flow.userId
    if (!userVerificatios[userId]) {
      userVerificatios[userId] = {
        name: flow.name || '',
        userId: flow.userId,
        flows: [],
        isPersonalIdVerified: false,
        isAddressVerified: false,
        personalIdVerification: undefined,
        addressVerification: undefined,
        latestVerificationDate: flow.date
      }
    }
    const userVerification = userVerificatios[userId]
    userVerification.flows.push(flow)
    if (flow.status === 'verified') {
      if (flow.flowId === MetamapFlow.PERSONAL_ID) {
        userVerification.isPersonalIdVerified = true
        userVerification.personalIdVerification = `Presonal Identification flow (${flow.flowId})`
        const step = flow.steps.find(x => x.step?.id === 'document-reading')
        if (step && _.get(step, 'step.data.address', false)) {
          userVerification.isAddressVerified = true
          userVerification.addressVerification = `Presonal Identification flow (${flow.flowId})`
        }
      }
      if (flow.flowId === MetamapFlow.PROOF_OF_RESIDENCY) {
        userVerification.isAddressVerified = true
        userVerification.addressVerification = `Proof of residency flow (${flow.flowId})`
      }
    }
    if (!userVerification.name && flow.name) {
      userVerification.name = flow.name
    }
  }
  const uvList = _.values(userVerificatios)
  
  for (const item of uvList) {
    item.latestVerificationDate = item?.flows[0]?.date
  }

  uvList.sort((a, b) => b.latestVerificationDate.valueOf() - a.latestVerificationDate.valueOf())

  return uvList
}

export function groupToFlows(steps: IMetamapPayload[]): IFlow[] {
  const map: { [key: string]: IFlow } = {}
  steps.forEach(step => {
    const processId = step.metadata?.processId
    if (!map[processId]) {
      map[processId] = {
        flowId: step.flowId,
        verificationId: processId,
        date: moment(step.timestamp).toDate(),
        name: '',
        status: '',
        steps: [],
        userId: step?.metadata?.userId
      }
    }
    const flow = map[processId]
    flow.steps.push(step)

    if (step.step?.id === 'document-reading') {
      flow.name = _.get(step, 'step.data.fullName.value', '')
    }
    const verificationId = step.resource?.split('/').pop()
    if (verificationId) {
      flow.verificationId = verificationId
    }
    if (step.identityStatus) {
      flow.status = step.identityStatus
    }
    if (step.eventName === 'verification_expired') {
      flow.status = 'expired'
    }
  })
  return Object.values(map)
}

export function getNamesFromFLows(flows: IFlow[]) {
  let verifiedPersonal, verifiedAddress
  for (const flow of flows) {
    if (flow.status === 'verified') {
      if (flow.flowId === MetamapFlow.PERSONAL_ID) {
        verifiedPersonal = flow
      }
      if (flow.flowId === MetamapFlow.PROOF_OF_RESIDENCY) {
        verifiedAddress = flow
      }
    }
  }
  return {
    personalId: verifiedPersonal ? getName(verifiedPersonal) : undefined,
    address: verifiedAddress ? getName(verifiedAddress) : undefined
  }
}

function getName(flow: IFlow): string | undefined {
  const step = flow.steps.find(x => x.step?.id === 'document-reading')
  return _.get(step, 'step.data.fullName.value')
}

export function getFlowName(flowId: string) {
  switch (flowId) {
    case MetamapFlow.PERSONAL_ID:
      return 'Personal Identification'
    case MetamapFlow.PROOF_OF_RESIDENCY:
      return 'Proof of residency'
    case MetamapFlow.EMAIL:
      return 'Email verification'
    default:
      return flowId
  }
}

export function metamapDashboardUrl(verificationId: string): string {
  return `https://dashboard.getmati.com/identity/624d7f4d6cc7f4001cb5d2bb/verification/${verificationId}`
}

export function filterUserVerifications(
  userVerifications: IUserVerifications[],
  filter: VerificationStatus | 'expired'
) {
  const filtered: { [key: string]: IUserVerifications } = {}
  for (const item of userVerifications) {
    for (const flow of item.flows) {
      if (flow.status === filter) {
        if (!filtered[item.userId]) {
          filtered[item.userId] = {
            ...item,
            flows: []
          }
        }
        filtered[item.userId].flows.push({ ...flow })
      }
    }
  }
  return _.values(filtered)
}
