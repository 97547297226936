import { Button, Divider, Dropdown, Menu, message, Modal, PageHeader, Table } from 'antd';
import { useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import Scaffold from '../components/Scaffold';
import { getCompanies } from '../data/EkisClient';
import ICompany from '../models/ICompany';
import { showCompanyForm } from './CompanyForm';
import Titlebar from '../components/Titlebar';

export default function CompaniesScreen() {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<ICompany[]>([])
  const [selected, setSelected] = useState<ICompany>()

  const columns: any[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 100
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'RFC',
      dataIndex: 'rfc'
    },
    {
      title: 'Email',
      dataIndex: 'emial'
    },
    {
      title: 'Phone',
      dataIndex: 'phone'
    },
    {
      title: 'CreatedOn',
      dataIndex: 'createdOn'
    },
    {
      title: 'Actions',
      render: renderActions
    }
  ]

  function renderActions(data: ICompany) {
    const menu = (
      <Menu>
        <Menu.Item key="1" onClick={() => setSelected(data.rfcData)}>
          RFC Data
        </Menu.Item>
      </Menu>
    );
    return (
      <Dropdown overlay={menu}>
        <Button>Actions</Button>
      </Dropdown>
    )
  }

  function fetchData() {
    setLoading(true)
    getCompanies()
      .then(data => {
        setData(data)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        message.error('Unable to load data')
      })
  }

  useEffect(() => {
    fetchData()

    const interval = setInterval(() => {
      getCompanies().then(data => setData(data))
    }, 5000)
    return () => clearInterval(interval)
  }, [])

  return (
    <Scaffold>
      <Titlebar title='Companies' />
      <PageHeader
        title={`Total: ${data.length}`}
        extra={<Button onClick={() => showCompanyForm()}>Add company</Button>}
      >
      </PageHeader>
      <Divider />
      <Table
        loading={loading}
        columns={columns}
        dataSource={data}
        rowKey='id'
        pagination={false}
      />
      <Modal
        title="RFC Data"
        visible={!!selected}
        onCancel={() => setSelected(undefined)}
        cancelText="Close"
        okButtonProps={{ style: { display: "none" } }}
        width={800}
      >
        <ReactJson src={selected!} />
      </Modal>
    </Scaffold >
  )
}
